import React from 'react';
import styled from "styled-components";

const SwitchContainer = styled.label`
  position: relative;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  position: absolute;
  left: -9999px;

  &:checked + span {
    background-color: #0d6efd;

    &:before {
      left: calc(100% -2px);
      transform: translateX(100%);
    }
  }
`;

const Slider = styled.span`
  display: flex;
  cursor: pointer;
  width: 35px;
  height: 17px;
  border-radius: 100px;
  background-color: #bfbfbf;
  position: relative;
  transition: background-color 0.2s, box-shadow 0.2s;

  &:before {
    position: absolute;
    content: "";
    top: 3px;
    left: 5px;
    height: 11px;
    width: 13px;
    border-radius: 21px;
    transition: 0.2s;
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  }
`;

export const SwitchToggle = React.forwardRef((props, ref) => {
  return (
    <SwitchContainer>
      <Input type="checkbox" ref={ref} {...props} />
      <Slider></Slider>
      <label style={{marginLeft: "7px"}} className="form-check-label"
             htmlFor={props.id}>
        {props.label}
      </label>
    </SwitchContainer>
  );
})
