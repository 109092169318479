import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../util/Apis";
import PangolinLoteForm from "./PangolinLoteForm";

const PangolinLoteNew = ({setAction, setTitle}) => {

  const create = async (params) => {
    return await axios.post(Apis.TGM + '/pangolin-lote', params).catch((err) => {
    });
  }

  useEffect(() => {
    setTitle("Nuevo lote");
  }, []);

  return (
    <>
      <PangolinLoteForm value={{}} onSubmit={create} setAction={setAction}/>
    </>
  );
};

export default PangolinLoteNew;
