import styled from "styled-components";

const PageSection = styled.div`
  margin: 11px 8px;
   
  @media (min-width: 700px) {
    margin: 22px 22px;
  }
  
  @media screen and (min-width: 1024px){
    margin-left: 32px;
    margin-right: 32px;
  }
  
  @media screen and (min-width: 1400px){
    margin-left: 40px;
    margin-right: 40px;
  }
`;

export default PageSection;
