
import {FaChevronLeft} from 'react-icons/fa';
import styles from './go-back.module.scss'

type GoBackProps = {
  text?: string;
  onClick?: (props: any) => void;
};


const GoBack: React.FC<GoBackProps> = ({text, onClick}) => {
  return (
    <span className={`${styles['go-back-container']}`} onClick={onClick}>
      <FaChevronLeft />
      <span>{text}</span>
    </span>
  );
};

export default GoBack;
