import React from 'react';
import styles from './sidebar.module.scss';

type SidebarProps = {
  logo?: string
  isOpen?: boolean
  items?: React.ReactNode
  menus?: React.ReactNode
  brand?: string
}

const Sidebar: React.FC<SidebarProps> = ({logo = '', isOpen = true, brand = '', items, menus}) => {

  return (
    <aside className={`${styles['sidebar-container']} ${!isOpen ? styles['close'] : ''}`}>
      <div>
        <div>
          <div>
            <img src={logo} />
          </div>
          {items}
        </div>
      </div>
      <div>
        <div className={`${styles['title']}`}>
          {brand}
        </div>
        {menus}
      </div>
    </aside>
  )
}

export default Sidebar