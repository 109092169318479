"use client"
import { useState } from 'react';

type HookProps = {
  defaultValue?: boolean;
  onOpen?: (props: any) => void;
  onClose?: (props: any) => void;
};

const useToggle = ({ onOpen = () => { }, onClose = () => { }, defaultValue = false }: HookProps = {}) => {
  const [isOpen, setIsOpen] = useState(defaultValue);

  const toggler = (props: any) => {
    const nextIsOpen = !isOpen;
    setIsOpen(nextIsOpen);
    if (nextIsOpen) {
      onOpen(props);
    } else {
      onClose(props);
    }
  };

  return { isOpen, toggler };
};

export default useToggle;
