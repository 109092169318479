import React, {useEffect} from 'react';
import PageContainer from "../components/PageContainer";
import PageSection from "../components/PageSection";
import PageTitle from "../components/Layaout/PageTitle";
import {Apis} from "../util/Apis";
import useFetch from "../hooks/useFetch";
import {ContainerLoading} from "./SalasList";
import useEditModal from "../hooks/useEditModal";
import UserEdit from "./UserEdit";
import GenericModal2 from "../hooks/GenericModal2";
import {AiOutlineSelect} from "react-icons/ai";
import {Loading} from "../components/forms/Loading";
import {Table} from "../components/forms/Table";

const UserList = () => {

  const {data, loading, fetchData, error} = useFetch(Apis.USER + '/internal/users/user-wgt', [])

  const {wrapper: wrapper1, refresh: refresh1, editAction} = useEditModal({
    editComponent: <UserEdit/>,
  })

  useEffect(() => {
    fetchData()
  }, [refresh1])

  const columns = [
    {Header: 'USUARIO', accessor: 'username'},
    {
      Header: 'TENANT',
      accessor: 'tenant',
      Cell: ({cell}) => (
        <div style={{cursor: 'pointer'}} onClick={() => editAction(cell.row.original.id)}>
          <AiOutlineSelect/>
          <span>{cell.row.original.tenant}</span>
        </div>
      )
    }
  ];

  return (
    <PageContainer>
      <PageSection>
        <PageTitle title={'Usuarios'}/>
      </PageSection>

      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <Table columns={columns} data={data}/>
        }
      </PageSection>
      <GenericModal2  {...wrapper1}/>
    </PageContainer>
  );
};

export default UserList;
