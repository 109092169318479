import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import {useParams} from "react-router";
import PageSection from "../../../components/PageSection";
import {Col, Row} from "react-bootstrap";
import FormGroup from "../../../components/FormGroup";
import Input, {SelectV2, TextArea} from "../../../components/forms/FormEl";

const CommentForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, watch, reset, control} = useForm();

  const {id} = useParams()

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  return (
    <>
      <PageSection>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Tipo</label>
              <SelectV2 options={
                [
                  {id: 1, name: 'General'},
                  {id: 2, name: 'Averia'},
                  {id: 3, name: 'Reparado'},
                ]
              }  {...register('type', {required: "Campo obligatorio"})}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Comentario</label>
              <TextArea key={id} {...register('comment')}/>
            </FormGroup>
          </Col>
        </Row>
      </PageSection>
    </>
  );
};

export default CommentForm;
