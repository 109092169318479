import React, {useEffect} from 'react';
import PageCardTitle from "../../components/Layaout/CardTitle";
import {Button, Card, Select} from "@wargostec/react-components";
import PageSection from "../../components/PageSection";
import {Col, Row} from "react-bootstrap";
import FormGroup from "../../components/FormGroup";
import {Controller, useForm} from "react-hook-form";
import {Apis} from "../../util/Apis";
import SwitchToggle from "../../components/Layaout/SwitchToggle";
import Input, {SelectV2} from "../../components/forms/FormEl";
import {Fileuploader} from "../../components/forms/FileUploader";
import SelectTenant from "../../Selects/SelectTenant";

const SalasForm = ({value, onSubmit, mode = true}) => {

  const {watch, register, handleSubmit, reset, control, formState: {errors}} = useForm();

  const watchOnline =  watch('config.onlineSystem')

  const querystring = window.location.search
  const query = new URLSearchParams(querystring)
  const tab = query.get('tab')

  useEffect(() => {
    if (tab && tab === 'billing') {
      const billingSection = document.getElementById('billing-section');
      if (billingSection) {
        billingSection.scrollIntoView({behavior: 'smooth'});
      }
    }
  }, [query])

  useEffect(() => {
    if (value) {
      reset(value)
    }
    if (value && value.billing && value.billing.fee) {
      value.billing.fee = value.billing.fee / 100
      reset(value)
    }
  }, [value])

  return (
    <>
      <PageCardTitle title={'Logo'}/>
      <Card>
        <PageSection style={{padding: '20px'}}>
          <Row>
            <FormGroup>
              <Row>
                <Col style={{textAlign: "center"}}>
                  <Controller name="logoUrl" control={control}
                              render={({field}) => <Fileuploader
                                folder={'logo'}
                                {...field}
                                baseURL={Apis.FILE}
                                assetsURL={`https://wgt-tmp-uploads.s3.amazonaws.com/`}/>
                              }/>
                </Col>
              </Row>
            </FormGroup>
          </Row>
        </PageSection>
      </Card>

      <PageCardTitle title={'General'}/>
      <Card>
        <PageSection style={{padding: '20px'}}>
          <Row>
            <FormGroup>
              <label>SalaID (*)</label>
              <Input type={'number'} {...register('salaId')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Tenant</label>
              <Controller
                name="tenant"
                control={control}
                render={({field}) => <SelectTenant {...field} />}
              />
              {errors.tenant && <p style={{color: 'red'}}>{errors.tenant?.message}</p>}
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Tipo</label>
              <SelectV2 options={
                [
                  {id: 'casino', name: 'Casino'},
                  {id: 'office', name: 'Asistencia'},
                  {id: 'vet', name: 'Veterinaria'},
                  {id: 'sportbet', name: 'Apuestas'},
                ]
              }  {...register('type', {required: "Campo obligatorio"})}/>
              {errors.tenant && <p style={{color: 'red'}}>{errors.tenant?.message}</p>}
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Nombre (*)</label>
              <Input type={'text'} {...register('name')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Token</label>
              <Input type={'text'} {...register('token', {required: "Campo obligatorio"})}/>
              {errors.token && <p style={{color: 'red'}}>{errors.token?.message}</p>}
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Hash</label>
              <Input type={'text'} {...register('hash', {required: "Campo obligatorio"})}/>
              {errors.hash && <p style={{color: 'red'}}>{errors.hash?.message}</p>}
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Pais (*)</label>
              <SelectV2 options={
                [
                  {id: 'PE', name: 'PERU'},
                  {id: 'CO', name: 'COLOMBIA'},
                  {id: 'GT', name: 'GUATEMALA'}
                ]
              } {...register('countryIso')}/>
            </FormGroup>
          </Row>


          <Row>
            <FormGroup>
              <label>Registro MINCETUR</label>
              <Input type={'number'} {...register('registroMincetur')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Direccion</label>
              <Input type={'text'} {...register('address')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Company ID </label>
              <Input type={'number'} {...register('companyId')}/>
            </FormGroup>
            <FormGroup>
              <label>Company Name</label>
              <Input type={'text'} {...register('companyName')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>RUC</label>
              <Input type={'number'} {...register('ruc')}/>
            </FormGroup>
          </Row>
        </PageSection>
      </Card>

      <PageCardTitle title={'Facturación'}/>
      <Card>
        <PageSection style={{padding: '20px'}} id="billing-section">
          <Row>
            <FormGroup>
              <label>Habilitado</label><br/>
              <SwitchToggle
                label={'Si'}
                {...register("billing.active")}/>
            </FormGroup>
            <FormGroup>
              <label>RUC</label>
              <Input type={'number'} {...register('billing.ruc')}/>
            </FormGroup>
            <FormGroup>
              <label>Cantida de maquinas</label>
              <Input type={'number'} {...register('billing.machinesQuantity')}/>
            </FormGroup>
            <FormGroup>
              <label>Plan</label>
              <SelectV2 options={
                [
                  {id: 'coupon', name: 'Cupones'},
                  {id: 'standard', name: 'Pro'},
                  {id: 'premium', name: 'Premium'},
                  {id: 'online_base', name: 'Online Base'},
                  {id: 'online_pro', name: 'Online Pro'},
                  {id: 'online_premium', name: 'Online Premium'},
                ]
              }  {...register('billing.plan')}/>
            </FormGroup>
            <FormGroup>
              <label>Pago</label>
              <SelectV2 options={
                [
                  {id: 'monthly', name: 'Mensual'},
                  {id: 'yearly', name: 'Anual'},
                ]
              }  {...register('billing.paymentCycle')}/>
            </FormGroup>
            <FormGroup>
              <label>Costo x maquina</label>
              <Input
                type="number"
                step="0.01"
                {...register('billing.fee', {
                  pattern: {
                    value: /^\d+(\.\d{1,2})?$/,
                    message: 'Por favor, introduce un número con un máximo de dos decimales.',
                  },
                  required: 'Este campo es obligatorio.',
                })}
              />
              {errors.billing?.fee && <p style={{color: 'red'}}>{errors.billing?.fee.message}</p>}
            </FormGroup>
            <FormGroup>
              <label>Moneda</label>
              <Select {...register('billing.currency')}>
                <option value={'PEN'}>PEN</option>
                <option value={'USD'}>USD</option>
              </Select>
            </FormGroup>
            <FormGroup>
              <label>Correo</label>
              <Input type={'text'} {...register('billing.email')}/>
            </FormGroup>
            <FormGroup>
              <label>Descripcion de factura</label>
              <Input type={'text'} {...register('billing.invoiceDescription')}/>
            </FormGroup>
            {
              mode && value && value.daysLate > 0 &&
              <label style={{color : 'red'}}>Dias de retraso : {value && value.daysLate}</label>
            }
          </Row>
        </PageSection>
      </Card>

      <PageCardTitle title={'Tipo de moneda'}/>
      <Card>
        <PageSection style={{padding: '20px'}}>
          <Row>
            <FormGroup>
              <label>Moneda</label>
              <SelectV2 options={
                [
                  {id: 'PEN', name: 'PEN'},
                  {id: 'USD', name: 'USD'},
                  {id: 'EUR', name: 'EUR'},
                  {id: 'COP', name: 'COP'},
                  {id: 'GTQ', name: 'GTQ'},
                ]
              } {...register('currency.currencyIso')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Simbolo</label>
              <Input type={'text'} {...register('currency.currencySymbol')}/>
            </FormGroup>
          </Row>
        </PageSection>
      </Card>

      <PageCardTitle title={'Sistema online'}/>
      <Card>
        <PageSection style={{padding: '20px'}}>
          <Row>
            <FormGroup>
              <label>Scanner local</label><br/>
              <SwitchToggle
                label={'Sí'}
                {...register("localScanner")}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Sistema online</label>
              <SelectV2
                options={
                [
                  {id: 'ITS', name: 'ITS'},
                  {id: 'COLOSSUS', name: 'COLOSSUS'},
                  {id: 'LINKTEK', name: 'LINKTEK'},
                  {id: 'DRGT', name: 'DRGT'},
                  {id: 'SIELCOMV2', name: 'SIELCOMV2'},
                  {id: 'CASINOTRAC', name: 'CASINOTRAC'},
                  {id: 'GEMERALD', name: 'GEMERALD'},
                  {id: 'UGS', name: 'UGS'},
                  {id: 'FERAL', name: 'FERAL'},
                  {id: 'WINSYSTEMS', name: 'WINSYSTEMS'},
                  {id: 'WARGOS', name: 'WARGOS'}
                ]
              } {...register('config.onlineSystem')}/>
            </FormGroup>
          </Row>

          <Row>
            <FormGroup>
              <label>URL PUBLIC</label>
              <Input type={'text'} {...register('config.onlineUrl')}/>
            </FormGroup>
          </Row>

          <Row>
            <FormGroup>
              <label>URL PRIVATE</label>
              <Input type={'text'} {...register('config.onlineUrlPrivate')}/>
            </FormGroup>
          </Row>

          <Row>
            <FormGroup>
              <label>Usuario</label>
              <Input type={'text'} {...register('config.onlineUsername')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Password</label>
              <Input type={'text'} {...register('config.onlinePassword')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>LinktekPages</label>
              <Input type={'text'} {...register('config.linktekPages')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Period Scan</label>
              <Input type={'text'} {...register('config.periodScan')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Period Scan Sessions</label>
              <Input type={'text'} {...register('config.periodScanSessions')}/>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Scan Meter</label><br/>
              <SwitchToggle
                label={'Si'}
                {...register("config.scanMeter")}/>
            </FormGroup>
          </Row>

          <Row>
            <FormGroup>
              <label>Scan Card</label><br/>
              <SwitchToggle
                label={'Si'}
                {...register("config.scanCard")}/>
            </FormGroup>
          </Row>

        </PageSection>
      </Card>

      <PageCardTitle title={'Servidor'}/>
      <Card>
        <PageSection style={{padding: '20px'}}>
          <Row>
            <FormGroup>
              <label>Versión</label>
              <SelectV2 options={
                [
                  {id: 'stable', name: 'stable'},
                  {id: 'latest', name: 'latest'},
                  {id: 'beta', name: 'beta'}
                ]
              } {...register('versionChannel')}/>
            </FormGroup>
          </Row>
        </PageSection>
      </Card>

      <div>
        <Button variant={'primary'} onClick={handleSubmit(onSubmit)} text={'Guardar'}/>
      </div>
    </>
  )
}

export default SalasForm;
