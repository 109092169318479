import React, {useEffect, useState} from 'react';
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import axios from "axios";
import useEditModal from "../hooks/useEditModal";
import {Modalverification} from "../components/forms/ModalVerification";
import PageSection from "../components/PageSection";
import PageTitle from "../components/Layaout/PageTitle";
import {ContainerLoading} from "./SalasList";
import GenericModal2 from "../hooks/GenericModal2";
import BannerEdit from "./BannerEdit";
import BannerNew from "./BannerNew";
import {MdFiberManualRecord} from "react-icons/md";
import {Loading} from "../components/forms/Loading";
import {Table} from "../components/forms/Table";
import {Button} from "@wargostec/react-components";
import {DateTime} from "luxon";

const BannerList = () => {

  const {loading, data, fetchData} = useFetch(Apis.SALAS + '/banner', []);

  const deleteElement = (id) => {
    axios.delete(Apis.SALAS + '/banner/' + id)
      .then(res => {
        fetchData()
      })
      .catch(error => {
      })

  }

  const {wrapper: wrapper1, refresh: refresh1, addAction, editAction} = useEditModal({
    editComponent: <BannerEdit/>,
    addComponent: <BannerNew/>
  })

  useEffect(() => {
    fetchData()
  }, [refresh1])

  const columns = [
    {Header: 'Sala', accessor: 'salaId'},
    {Header: 'Nombre', accessor: 'salaName'},
    {Header: 'Titulo', accessor: 'title'},
    {Header: 'Mensaje', accessor: 'message'},
    {
      Header: 'Habilitado',
      accessor: 'enabled',
      Cell: ({row}) => {
        return (
          row.values.enabled
            ?
            <span>SI</span>
            :
            <span>NO</span>

        )
      }
    },
    {
      Header: 'Fijado',
      accessor: 'fixed',
      Cell: ({row}) => {
        return (
          row.values.fixed
            ?
            <span>SI</span>
            :
            <span>NO</span>

        )
      }
    },
    {Header: 'Alerta', accessor: 'alert'},
    {
      Header: 'Color',
      accessor: 'hexColor',
      Cell: ({row}) => {
        return (
          row.values.hexColor
            ?
            <MdFiberManualRecord color={row.values.hexColor} size={28}/>
            :
            <MdFiberManualRecord color={'gray'} size={28}/>


        )
      }
    },
    {Header: 'Tipo', accessor: 'type'},
    {Header: 'Comentario', accessor: 'comment'},
    {
      Header: 'Actualizado',
      accessor: 'updatedAt',
      Cell: ({cell}) => {
        return (
          <div>
            {
              cell.row.original.updatedAt ?
                <span>{DateTime.fromISO(cell.row.original.updatedAt).toFormat("yyyy'-'LL'-'dd' 'HH':'mm' ")}</span>
                :
                <span>---</span>

            }
          </div>
        )
      }
    },
    {
      Header: 'Acciones',
      Cell: ({cell}) => {
        const [isDelete, setIsDelete] = useState(false);
        return (
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <Button text={'Editar'} size={'xs'} variant={'success'} onClick={() => editAction(cell.row.original.id)}/>
            <Button text={'Eliminar'} size={'xs'} variant={'danger'} onClick={() => setIsDelete(true)}/>
            <Modalverification
              show={isDelete}
              handleClose={() => setIsDelete(false)}
              title='¿Seguro que desea eliminar este banner?'
              handleDelete={() => deleteElement(cell.row.original.id)}
            />
          </div>
        );
      },
    },

  ];

  return (
    <>
      <PageSection>
        <PageTitle title={'Banners'}/>
        <Button text={'Nuevo'} variant={'primary'} onClick={addAction}/>
      </PageSection>

      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <Table columns={columns} data={data}/>
        }
      </PageSection>
      <GenericModal2 {...wrapper1}/>
    </>
  );
};

export default BannerList;
