import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import {useParams} from "react-router";
import {Col, Row} from "react-bootstrap";
import FormGroup from "../../components/FormGroup";
import Input from "../../components/forms/FormEl";
import PageSection from "../../components/PageSection";
import SwitchToggle from "../../components/Layaout/SwitchToggle";

const TenantForm = ({value, onSubmit, setAction, mode = true}) => {

  const {register, handleSubmit, watch, reset, control} = useForm();

  const {id} = useParams()

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  return (
    <div>
      <PageSection>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Name</label>
              <Input type={'text'} key={id} {...register('name')} disabled={mode}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label>Habilitado</label><br/>
              <SwitchToggle
                label={'Si'}
                {...register("enabled")}/>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label>Gaming</label><br/>
              <SwitchToggle
                label={'Si'}
                {...register("gaming")}/>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label>Asis</label><br/>
              <SwitchToggle
                label={'Si'}
                {...register("asis")}/>
            </FormGroup>
          </Col>
        </Row>
      </PageSection>

    </div>
  );
};

export default TenantForm;
