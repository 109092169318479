import ReactLoading from 'react-loading';
import styles from './loader.module.scss';

type LoaderProps = {
  color?: string;
  height?: string | number;
  width?: string;
}

const Loader: React.FC<LoaderProps> = ({ color = 'black', height = 40, width = 40 }) => {
  return (
    <div className={styles['loader-container']}>
      <ReactLoading type={'spin'} color={color} height={height} width={width} />
      <span>Cargando...</span>
    </div>
  )
}

export default Loader