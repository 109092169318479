import React from "react";
import styled from "styled-components";
import { useTable, useSortBy, usePagination } from "react-table";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { TbPlayerTrackNext, TbPlayerTrackPrev } from "react-icons/tb";
import {Button} from "@wargostec/react-components";

const StylesDiv = styled.div`
  .text-center {
    text-align: center;
  }
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  table {
    width: 100%;
    border-spacing: 0;
    tbody {
      tr {
        :hover {
          background-color: #ECECEC;
        }
      }
    }
    th,
    td {
      padding: 0.5rem;
    }
    th {
      text-align: left;
      svg {
        cursor: pointer;
        margin-left: 0.5em;
      }
    }
  }
`;

const ContainerPagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
`;

export function Table({ columns, data, pagination, paginationSize, ...props }) {
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: pagination ? paginationSize ? paginationSize : 10 : data.length },
    },
    useSortBy,
    usePagination,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  return (
    <StylesDiv {...props}>
      <div className="table-responsive">
        <table {...props} {...getTableProps()}>
          <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps([
                  {
                    className: column.headerClassName,
                    style: column.headerStyle,
                  },
                  column.getSortByToggleProps()
                ])}>
                  {column.render("Header")}
                  {column.disableSortBy ? null : <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FaSortDown size="0.9em" />
                        ) : (
                          <FaSortUp size="0.9em" />
                        )
                      ) : (
                        <FaSort size="0.9em" />
                      )}
                    </span>}
                </th>
              ))}
            </tr>
          ))}
          </thead>
          <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps(
                      [
                        {
                          className: cell.column.columnClassName,
                          style: cell.column.columnStyle,
                        },
                      ]
                    )}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
      {pagination && (
        <ContainerPagination>
          <Button
            size="xs"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            title={<TbPlayerTrackPrev />}
          />
          <Button
            size="xs"
            variant={'light'}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            title={"Previus"}
          />
          <span>
            {"Page"}{" "}
            <strong>
              {pageIndex + 1} {"de"} {pageOptions.length}
            </strong>
          </span>
          <span>
            | {"go to page"} :{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
              min="1"
              max={pageOptions.length}
              style={{ width: "50px", padding: "2px", outline: "none" }}
            />
          </span>
          <Button size="xs" onClick={() => nextPage()} disabled={!canNextPage} text={'Next'}/>
          <Button
            size="xs"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            title={<TbPlayerTrackNext />}
          />
        </ContainerPagination>
      )}
    </StylesDiv>
  );
}
