import styles from './view-content.module.scss'

type ViewContentProps = {
  children?: React.ReactNode
  isOpen?: boolean
}

const ViewContent: React.FC<ViewContentProps> = ({isOpen = false, children}) => {
  return (
    <div className={`${styles['view-content']} ${!isOpen ? styles['close'] : ''}`}>
      {children}
    </div>
  )
}

export default ViewContent