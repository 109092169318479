import React from 'react'
import styles from './row.module.scss'

type RowProps = {
  children?: React.ReactNode
}

const Row: React.FC<RowProps> = ({children}) => {
  return (
    <div className={styles['row-container']}>
      {children}
    </div>
  )
}

export default Row