import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import {Col, Row} from "react-bootstrap";
import FormGroup from "../../../components/FormGroup";
import PageSection from "../../../components/PageSection";
import SelectFirmware from "../../../Selects/SelectFirmware";
import SelectSalas from "../../../Selects/SelectSalas";

const RolloutForm = ({value, onSubmit, setAction}) => {
  const {register, handleSubmit, watch, reset, control} = useForm();

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  return (
    <div>
      <PageSection>
        <Row>
          <Col>
            <FormGroup>
              <label>Sala Id</label>
              <Controller
                name="salaId"
                control={control}
                render={({field}) => <SelectSalas {...field} />}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label>Firmware asignado</label>
              <Controller
                name="assignedFirmware"
                control={control}
                render={({field}) => <SelectFirmware {...field} />}
              />
            </FormGroup>
          </Col>
        </Row>
      </PageSection>
    </div>
  );
};

export default RolloutForm;
