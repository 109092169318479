import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../util/Apis";
import GameForm from "./GameForm";
import {useParams} from "react-router-dom";

const GameNew = ({setAction, setTitle}) => {

  const {modelId} = useParams()

  const save = async (params) => {
    params.modelId= parseInt(modelId)
    await axios.post(Apis.SALAS + `/slot-model/${modelId}/add-game`, params)
  }

  useEffect(() => {
    setTitle("Nueva juego")
  }, [])

  return (
    <GameForm value={{}} onSubmit={save} setAction={setAction}/>
  );
};

export default GameNew;
