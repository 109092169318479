import { forwardRef, useState } from 'react';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { useToggle } from '../../hooks';
import styles from './input.module.scss'

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  helper?: string
  error?: string
  type?: 'text' | 'date' | 'datetime-local' | 'time' | 'number' | 'email' | 'search' | 'tel' | 'url' | 'month' | 'week' | 'password'
  iconPosition?: 'left' | 'right'
  icon?: React.ReactNode
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { id = '', label, helper, error, icon, iconPosition = 'left', type = "text", disabled, onChange, onFocus, onBlur, ...props }, ref
  ) => {
    const { isOpen, toggler } = useToggle({});
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      onFocus && onFocus(e);
      setIsFocused(true);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      onBlur && onBlur(e);
      setIsFocused(false);
    };

    return (
      <div className={`${styles['input-text-container']}`}>
        {label && <label htmlFor={id}>{label}</label>}
        <div className={`${isFocused ? styles['focus'] : ''} ${type === "password" ? styles['right'] : ''} ${icon && type === 'text' ? styles[iconPosition] : ''} ${disabled ? styles['disabled'] : ''} ${error && error.trim().length > 0 ? styles['error'] : ''}`}>
          {icon && type === 'text' && iconPosition === 'left' && icon}
          <input
            id={id}
            type={type === 'password' ? (isOpen ? 'text' : 'password') : type}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={onChange}
            disabled={disabled}
            ref={ref}
            {...props}
          />
          {icon && type === 'text' && iconPosition === 'right' && icon}
          {type === 'password' ? isOpen ? <FaRegEyeSlash onClick={toggler} cursor={'pointer'} /> : <FaRegEye onClick={toggler} cursor={'pointer'} /> : null}
        </div>
        {helper && (!error || disabled) && <label>{helper}</label>}
        {error && !disabled && <label className={`${styles['error']}`}>{error}</label>}
      </div>
    );
  }
);

Input.displayName = "Input"

export default Input;