import React, {useMemo} from 'react';
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import {Table} from "@wargostec/react-components";
import PageSection from "../../components/PageSection";
import PageTitle from "../../components/Layaout/PageTitle";
import {ContainerLoading} from "../SalasList";
import {Loading} from "../../components/forms/Loading";
import PageContainer from "../../components/PageContainer";

const Tramas = () => {

  const {loading, data,fetchData} = useFetch(Apis.TGM + '/datacenter/status', []);

  const createdValue = ({ getValue }) => {
  const value = getValue();
  return (
    <div>
      {value === 0 ? (
        <span>Todo enviado</span>
      ) : value > 0 ? (
        <span>Tramas pendientes</span>
      ) : (
        <span>El servidor no respondió</span>
      )}
    </div>
  );
};

  const columns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'salaId',
      },
      {
        header: 'Sala',
        accessorKey: 'salaName',
      },
      {
        header: 'Pendientes',
        accessorKey: 'pendingTramas',
        cell: createdValue
      },
      {
        header: 'Value',
        accessorKey: 'pendingTramas',
      },
    ]
    , []
  )

  return (
    <PageContainer>
      <PageSection>
        <PageTitle title={'Tramas'}/>
      </PageSection>

      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <Table columns={columns} data={data} pageSize={50} onRefresh={fetchData} sortArray={[{id: 'salaId', asc: true}]} searchable={true}/>
        }
      </PageSection>
    </PageContainer>
  );
};

export default Tramas;
