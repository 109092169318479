import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import {useParams} from "react-router";
import PageSection from "../../components/PageSection";
import {Col, Row} from "react-bootstrap";
import FormGroup from "../../components/FormGroup";
import Input from "../../components/forms/FormEl";

const FirmwareForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, watch, reset, control} = useForm();

  const {id} = useParams()

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])
  return (
    <div>
      <PageSection>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Versión</label>
              <Input type={'text'} key={id} {...register('version')}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>sha1</label>
              <Input  type={'text'} key={id} {...register('sha1')}/>
            </FormGroup>
          </Col>
        </Row>
      </PageSection>
    </div>
  );
};

export default FirmwareForm;
