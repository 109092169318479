import React from "react";
import styled from 'styled-components'
import {GrClose} from "react-icons/gr";

const SlidebarStyled = styled.div`
  position: fixed;
  bottom: 0;
  right: -440px;

  background: white;
  height: 100vh;
  width: 440px;
  z-index: 100;

  transition: transform 0.3s ease-in-out;
  transform: ${props => props.visible ? "translateX(-440px)" : "translateX(0px)"};

  box-shadow: var(--p-modal-shadow, 0 31px 41px 0 rgba(32, 42, 53, .2), 0 2px 16px 0 rgba(32, 42, 54, .08));

  .container {
    display: flex;
    flex-direction: column;
  }

  .header {
    display: flex;
    padding: 16px;
    justify-content: space-between;

    label {
      font-size: 1.4rem;
      font-weight: 600;
    }
  }
`

export function Slidebar({visible, children, onHide, title}) {
  return (<SlidebarStyled visible={visible}>
    <div className='container'>
      <div className='header'>
        <label>{title}</label>
        <span style={{cursor: 'pointer'}} onClick={onHide}>
            <GrClose/>
          </span>
      </div>
      {children}
    </div>
  </SlidebarStyled>)
}
