// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.GhX6AIlYBMj2szG_rvr9{display:flex;flex-direction:column;position:absolute;left:250px;height:100vh;width:calc(100% - 250px);transition:.3s all ease-in-out}div.GhX6AIlYBMj2szG_rvr9.m_an148fzHE1JS__dTSQ{left:50px;width:calc(100% - 50px)}@media screen and (max-width: 992px){div.GhX6AIlYBMj2szG_rvr9,div.GhX6AIlYBMj2szG_rvr9.m_an148fzHE1JS__dTSQ{left:0;width:100%}}`, "",{"version":3,"sources":["webpack://./src/components/view-content/view-content.module.scss"],"names":[],"mappings":"AAAA,yBACC,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,UAAA,CACA,YAAA,CACA,wBAAA,CACA,8BAAA,CAEA,8CACC,SAAA,CACA,uBAAA,CAIF,qCACC,uEAEC,MAAA,CACA,UAAA,CAAA","sourcesContent":["div.view-content {\n\tdisplay: flex;\n\tflex-direction: column;\n\tposition: absolute;\n\tleft: 250px;\n\theight: 100vh;\n\twidth: calc(100% - 250px);\n\ttransition: 0.3s all ease-in-out;\n\n\t&.close {\n\t\tleft: 50px;\n\t\twidth: calc(100% - 50px);\n\t}\n}\n\n@media screen and (max-width: 992px) {\n\tdiv.view-content,\n\tdiv.view-content.close {\n\t\tleft: 0;\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view-content": `GhX6AIlYBMj2szG_rvr9`,
	"close": `m_an148fzHE1JS__dTSQ`
};
export default ___CSS_LOADER_EXPORT___;
