import React, {useEffect} from "react";
import {Apis} from "../../util/Apis";
import axios from "axios"
import {useForm} from "react-hook-form";
import FormGroup from "../../components/FormGroup";
import Input from "../../components/forms/FormEl";


const UserGroupNew = ({ setAction, setTitle }) => {

  const { register, handleSubmit } = useForm();

  const save = (params) => {
    params.salaId = '999';
    return axios.post(Apis.USER + '/user-group', params);
  }

  useEffect(() => {
    setTitle("Nuevo Perfil")
    setAction(() => handleSubmit(save))
  }, [])

  return (
    <FormGroup>
      <label>Perfil</label>
      <Input type={'text'} {...register("name")} />
    </FormGroup>
  )
}

export default UserGroupNew
