// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hXdDP94TjmRLqG__dGqv{flex-basis:100%;flex:1;margin:0 10px}`, "",{"version":3,"sources":["webpack://./src/components/col/col.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,MAAA,CACA,aAAA","sourcesContent":[".col-container {\n  flex-basis: 100%;\n  flex: 1;\n  margin: 0 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"col-container": `hXdDP94TjmRLqG__dGqv`
};
export default ___CSS_LOADER_EXPORT___;
