import Modal from "react-bootstrap/Modal";
import React, {useState} from "react";
import {errorToast, successToast} from "../components/Layaout/ToastStyles";
import {Button} from "@wargostec/react-components";

const GenericModal2 = ({body, onHide, ...props}) => {

  const [action, setAction] = useState(null)
  const [title, setTitle] = useState('')
  const [loading, setLoading] = useState(false)

  function doModalAction(e) {
    e.preventDefault()
    setLoading(true)
    action().then(res => {
      setLoading(false)
      onHide()
      successToast()
    }).catch(err => {
      let toastrTxt = "Ocurrio un problema. Por favor intenta nuevamente"
      if (err.response !== undefined) {
        toastrTxt = err.response.data.message
      }

      errorToast(toastrTxt)
      setLoading(false)
    })
  }

  return (
    <Modal onHide={onHide} {...props}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <form onSubmit={doModalAction}>
      <Modal.Body>
        {
          body &&
          <div style={{display: 'block', flexDirection: 'row'}}>
            {React.cloneElement(body, {setAction, setTitle})}
          </div>
        }
      </Modal.Body>

      <Modal.Footer>
        <Button variant='light' onClick={onHide} type={"button"} text={'Cerrar'}/>
        <Button variant='primary' text={'Guardar'} type={"submit"} disabled={loading}/>
      </Modal.Footer>
      </form>
    </Modal>
  );
};

export default GenericModal2;
