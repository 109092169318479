import React, {useEffect} from 'react';
import axios from "axios";
import PageCardTitle from "../../components/Layaout/CardTitle";
import ActionEndpointForm from "./ActionEndpointForm";
import {useParams} from "react-router-dom";
import {Apis} from "../../util/Apis";

const ActionEndpointNew = ({setAction, setTitle}) => {

  const {actionId} = useParams()

  const create = async (params) => {
    params.actionId = actionId
    await axios.post(Apis.USER + '/actions-endpoints', params).catch((err) => {
    });
  }

  useEffect(() => {
    setTitle("Nuevo action endpoint");
  }, []);

  return (
    <>
      <PageCardTitle>
        Nuevo action endpoint
      </PageCardTitle>
      <ActionEndpointForm value={{}} onSubmit={create} setAction={setAction}/>

    </>
  );
};

export default ActionEndpointNew;
