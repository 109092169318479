import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../util/Apis";
import PageCardTitle from "../../../components/Layaout/CardTitle";
import RolloutForm from "./RolloutForm";

const RolloutNew = ({setAction, setTitle}) => {

  const create = async (params) => {
    await axios.post(Apis.TGM + '/pangolin-rollout/execute', params).catch((err) => {
    });
  }

  useEffect(() => {
    setTitle("Nuevo rollout");
  }, []);

  return (
    <>
      <PageCardTitle>
        Nuevo firmware
      </PageCardTitle>
      <RolloutForm value={{}} onSubmit={create} setAction={setAction}/>
    </>
  );
};

export default RolloutNew;
