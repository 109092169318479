import React, {useEffect} from 'react';
import PageSection from "../components/PageSection";
import {Col, Row} from "react-bootstrap";
import FormGroup from "../components/FormGroup";
import {Controller, useForm} from "react-hook-form";
import {useParams} from "react-router";
import Input from "../components/forms/FormEl";
import {Fileuploader} from "../components/forms/FileUploader";

const MakerForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, watch, reset, control} = useForm();

  const {id} = useParams()

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  return (
    <div>
      <PageSection>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>ID</label>
              <Input type={'number'} key={id} {...register('manufacturerId')}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Nombre</label>
              <Input  placeholder={"ejem... I.G.T."} type={'text'} key={id} {...register('name')}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Logo</label>
              <Controller name="logoURL" control={control} render={({field}) => <Fileuploader folder={'ads'} {...field}
                                                                                              assetsURL={`https://wgt-tmp-uploads.s3.amazonaws.com/`} baseURL={"https://api5.wgt.pe/xfile"}/>}/>
            </FormGroup>
          </Col>
        </Row>
      </PageSection>
    </div>
  );
};

export default MakerForm;
