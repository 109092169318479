import React, {useEffect, useState} from "react";
import {useWauth} from "../auth/WauthProvider";
import axios from "axios";
import {Apis} from "../util/Apis";
import {Navigate} from "react-router-dom";

const CallbackPage = () => {

  const {handleAuthentication, isAuthenticated } = useWauth()
  const [message, setMessage] = useState("Verificando cuenta")

  let referer = '/'

  const getUser = () => {
    axios.get(Apis.USER + `/users/me`)
      .then(res => {
        localStorage.setItem("name", JSON.stringify(res.data.user.name));
      })
  }

  useEffect(() => {
    handleAuthentication()
    getUser()
  }, [])

  if (isAuthenticated) {
    return <Navigate to={referer}/>
  }

  return (
    <div>
      {message}
    </div>
  )
};

export default CallbackPage;
