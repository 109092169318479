"use client"
import React, {useState} from 'react'
import {MenuProps} from '../menu'

type MenuListProps = {
  children?: React.ReactNode
}

const MenuList: React.FC<MenuListProps> = ({children}) => {
  const [selected, setSelected] = useState<string>('')

  return (
    <div>
      {React.Children.map(children, (child: React.ReactElement<MenuProps>, index) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {...child.props, selected: selected, setSelected: setSelected, key: index});
        }
      })}
    </div>
  )
}

export default MenuList
