import React, {useCallback, useEffect, useState} from 'react';
import {Button, Table} from "@wargostec/react-components";
import {Modalverification} from "../components/forms/ModalVerification";
import axios from "axios";
import {Apis} from "../util/Apis";
import PageSection from "../components/PageSection";
import {ContainerLoading} from "./SalasList";
import useFetch from "../hooks/useFetch";
import PageTitle from "../components/Layaout/PageTitle";
import {errorToast, successToast} from "../components/Layaout/ToastStyles";
import {useForm} from "react-hook-form";
import {ButtonGroup, FormGroup} from "react-bootstrap";
import SelectMaker from "../Selects/SelectMaker";
import {Loading} from "../components/forms/Loading";
import {Slidebar} from "../components/forms/Slidebar";
import {useNavigate, useSearchParams} from "react-router-dom";
import {FaCheckCircle} from "react-icons/fa";
import {TiDeleteOutline} from "react-icons/ti";

const ModelList = () => {

  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();
  const initialParams = Object.fromEntries(searchParams.entries());
  const [params, setParams] = useState(Object.fromEntries(searchParams));
  const {loading, data, fetchData} = useFetch(Apis.SALAS + '/slot-models', [], params);

  const {register, handleSubmit,setValue,reset} = useForm({defaultValues: params});

  const getData = (params) => {
    setParams(params)
    setSearchParams(params)
  }

  const [slideOpen, setSlideOpen] = useState(false)

  useEffect(() => {
    fetchData()
  }, [params, reset])

  useEffect(() => {
    if (initialParams.manufacturerId) {
      setValue('manufacturerId', initialParams.manufacturerId);
    }
  }, [initialParams, setValue]);

  function goEditModel(id, modelId) {
    navigate("/model-edit/" + id + "/" + modelId);
  }

  function goNewModel() {
    navigate("/modelo-new");
  }

  const deleteElement = (id) => {
    axios.delete(Apis.SALAS + '/slot-models/' + id)
      .then(res => {
        successToast('Se elimino correctamente')
        fetchData()
      })
      .catch(error => {
      })

  }

  const linkPhotoValue = useCallback((props) => {
    const renderValue = props.row.original.photoLink;

    const copyToClipboard = () => {
      if (renderValue) {
        navigator.clipboard.writeText(renderValue)
          .then(() => {
            successToast('Texto copiado al portapapeles');
          })
          .catch((err) => {
            errorToast('Error al copiar el texto: ', err);
          });
      }
    };

    return (
      <>
        {props.row.original.photoLink !== "" ? (
          <div style={{ textAlign: "center", cursor: 'pointer' }}>
            <FaCheckCircle color='green' onClick={copyToClipboard}/>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <TiDeleteOutline color='black' />
          </div>
        )}
      </>
    );
  }, []);

  const linkConnectorValue = props => (
    <>
      {props.row.original.connectorLink !== "" ? (
        <div style={{ textAlign: "center" }}>
          <FaCheckCircle color='green' />
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <TiDeleteOutline color='black' />
        </div>
      )}
    </>
  );

  const connectorIdValue = props => {
    return (
      <>
        {props.row.original.connectorId !== 0 ? (
          <div style={{ textAlign: "center" }}>
            <FaCheckCircle color='green' />
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <TiDeleteOutline color='black' />
          </div>
        )}
      </>
    )
  }

  const actionValue = useCallback(props => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isDelete, setIsDelete] = useState(false);
    return (
      <>
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <Button text={'Editar'} size={'xs'} variant={'success'} onClick={() => goEditModel(props.renderValue(), props.row.original.modelId)}/>
          <Button text={'Eliminar'} size={'xs'} variant={'danger'} onClick={() => setIsDelete(true)}/>
          <Modalverification
            show={isDelete}
            handleClose={() => setIsDelete(false)}
            title='¿Seguro que desea eliminar este Modelo?'
            handleDelete={() => deleteElement(props.renderValue())}
          />
        </div>
      </>
    );
  }, []);

  const columns = [
    {header: 'Id', accessorKey: 'modelId'},
    {header: 'Nombre', accessorKey: 'name'},
    {header: 'Alias', accessorKey: 'nickName'},
    {header: 'Fabricante', accessorKey: 'manufacturerName'},
    {header: 'Puertos', accessorKey: 'comPortsNumber',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
    },
    {header: 'Tipo', accessorKey: 'machineTypeId'},
    {
      header: 'Conector',
      accessorKey: 'connectorName',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: (props) => (props.row.original.connectorName ? props.row.original.connectorName : '---')
    },
    {header: 'Link de foto', accessorKey: 'photoLink',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: linkPhotoValue,
    },
    {header: 'Link de conector', accessorKey: 'connectorLink',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: linkConnectorValue,
    },
    {
      header: 'Acciones', accessorKey: 'id',
      meta: {
        headerClassName: 'text-center',
        columnClassName: 'text-center',
      },
      cell: actionValue,
    },

  ];

  return (
    <>
      <Slidebar title={'Filtros'} visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <FormGroup>
            <label>Fabricante</label>
            <SelectMaker {...register('manufacturerId')} defaultValue={initialParams.manufacturerId} />
          </FormGroup>
          <div style={{paddingTop: '10px'}}>
            <Button variant={'primary'} text='Buscar' onClick={handleSubmit(getData)}/>
          </div>
        </div>
      </Slidebar>

      <PageSection>
        <PageTitle title={'Modelo'}/>
        <ButtonGroup>
        <Button text={'Nuevo'} variant={'primary'} onClick={goNewModel}/>
        <div style={{paddingLeft: "10px"}} ></div>
        <Button variant={'primary'} text={'Filtrar'} onClick={() => setSlideOpen(!slideOpen)}/>
        </ButtonGroup>
      </PageSection>

      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <Table columns={columns} data={data} pageSize={200} searchable={true}/>
        }
      </PageSection>
    </>
  );
};

export default ModelList;
