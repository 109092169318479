import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import PageSection from "../../components/PageSection";
import {Row} from "react-bootstrap";
import FormGroup from "../../components/FormGroup";
import styled from "styled-components";


const BigInputStyled = styled.div`
  width: 100%;
  display: inline-block;
  font-size: 4rem;

  input {
    text-align: center;
    letter-spacing: 8px;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #566074;
    display: inline-block;
    font-size: inherit;
    height: 60px;
    line-height: 60px;
    outline: 0;
    padding: 0 20px;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    width: 100%;

    &:hover {
      border-color: #c0c4cc;
    }

    &:focus {
      //komercia
      //border-color: #4429b4;
      border: 2px solid #068eef;
      outline: 0;
      //background-color: #fff;
    }
      
  }
`

const BigInput = React.forwardRef((props, ref) => {
  return (
    <BigInputStyled>
      <input ref={ref} {...props} />
    </BigInputStyled>
  )
})

const ProgrammingForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, watch, reset, control} = useForm();

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])


  return (
    <div>
      <PageSection>
        <Row>
          <FormGroup>
            <label>Serie</label>
            <BigInput {...register('assignedSn')} autoFocus/>
          </FormGroup>
          {/*<FormGroup>*/}
          {/*  <label>SN</label>*/}
          {/*  <Input {...register('sn')} disabled/>*/}
          {/*</FormGroup>*/}
          {/*<FormGroup>*/}
          {/*  <label>IP Publica</label>*/}
          {/*  <Input {...register('publicIp')} disabled/>*/}
          {/*</FormGroup>*/}
        </Row>
      </PageSection>
    </div>
  );
};

export default ProgrammingForm;
