import React, {useCallback, useEffect, useMemo} from 'react';
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import useEditModal from "../../hooks/useEditModal";
import ChannelEdit from "./ChannelEdit";
import PageSection from "../../components/PageSection";
import PageTitle from "../../components/Layaout/PageTitle";
import {ContainerLoading} from "../SalasList";
import {Button, Table} from "@wargostec/react-components";
import GenericModal2 from "../../hooks/GenericModal2";
import {Loading} from "../../components/forms/Loading";
import PageContainerFull from "../../components/PageContainerFull";

const ChannelList = () => {

  const {loading, data, fetchData} = useFetch(Apis.SALAS + '/versions', []);

  const {wrapper: wrapper1, refresh: refresh1, addAction, editAction} = useEditModal({
    editComponent: <ChannelEdit/>
  })

  useEffect(() => {
    fetchData()
  }, [refresh1])

  const actionValue = useCallback(props => <>{
    <div>
      <Button text={'Editar'} size={'xs'} variant={'success'} onClick={() => editAction(props.renderValue())}/>
    </div>
  }</>, []);

  const nameValue = useCallback(props => <>
      {
        <div>
          {props.renderValue() ?
            <span>{props.renderValue()}</span> :
            <span>---</span>}
        </div>
      }
    </>
    , []);

  const columns = useMemo(
    () => [
      {header: 'Canal', accessorKey: 'name'},
      {header: 'Blue Reports', accessorKey: 'xbluereports'},
      {header: 'Blue Server', accessorKey: 'xblusvr'},
      {header: 'Tgm Ingest', accessorKey: 'xtgmingest'},
      {header: 'Datacenter', accessorKey: 'xdatacenter'},
      {header: 'Cloudsync', accessorKey: 'xcloudsync'},
      {
        header: 'Ticket Server',
        accessorKey: 'xticket',
        cell: nameValue
      },
      {header: 'Webnode', accessorKey: 'xwebnode'},
      {header: 'Fe-local', accessorKey: 'front'},
      {
        header: 'Acciones',
        accessorKey: 'id',
        cell: actionValue
      },
    ]
    , []
  )

  return (
    <PageContainerFull>
      <PageSection>
        <PageTitle title={'Versiones'}/>
        <div>
          <>
                {
                  loading ?
                    <ContainerLoading>
                      <Loading variant='secondary'/>
                    </ContainerLoading>
                    :
                    <Table columns={columns} data={data}/>
                }
          </>
        </div>
      </PageSection>
      <GenericModal2 {...wrapper1}/>

    </PageContainerFull>
  );
};

export default ChannelList;
