import styled from 'styled-components';

const TableResponsive = styled.div`
  overflow: auto;
`;

const TableStyled = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  th {
    padding: 8px;
  }
  .head {
    font-weight: 400;
    background-image: linear-gradient(45deg, #0674db, #069cf9);
    color: #ffffff;
  }
  td {
    font-weight: normal;
    padding: 5px;
  }
  .center {
    text-align: center;
  }
  .pointer {
    cursor: pointer;
  }
  tbody {
  tr {
    :hover {
      background: #ECECEC;
    }
  }
  }
`;

const Table = ({ children }) => {
  return (
    <TableResponsive>
      <TableStyled>
        {children}
      </TableStyled>
    </TableResponsive>
  );
};

export default Table;