import React, {useCallback, useEffect, useMemo} from 'react';
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import {DateTime} from "luxon";
import {Table} from "@wargostec/react-components";

const CommentList = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch(Apis.TGM + `/pangolin-comment?sn=${id}`);

  useEffect(() => {
    setTitle('Comentarios | SN : ' + id);
  }, []);

  const createdValue = useCallback(props => <>
      {
        <div>
          {props.renderValue() ?
            <span>{DateTime.fromISO(props.renderValue()).toFormat("yyyy'-'LL'-'dd' 'HH':'mm' ")}</span> :
            <span>---</span>}
        </div>
      }
    </>
    , []);

  const typeValue = useCallback(props => <>
    {
      <div>
        {props.renderValue() ?
          <span>{getTypeName(props.renderValue())}</span> :
          <span>---</span>}
      </div>
    }
  </>, []);

  const getTypeName = (type) => {
    switch (type) {
      case 1:
        return 'General';
      case 2:
        return 'Averíado';
      case 3:
        return 'Reparado';
      default:
        return '---';
    }
  }

  const columns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Tipo',
        accessorKey: 'type',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: typeValue
      },
      {
        header: 'Comentario',
        accessorKey: 'comment',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Fecha',
        accessorKey: 'createdAt',
        cell: createdValue,
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      }
    ]
    , []
  )

  return (
    <>
      {
        data &&
        <Table columns={columns} data={data && data} sortArray={[{id: 'id', desc: true}]}
               pageSize={10}
               searchable={true}/>
      }
    </>
  );
};

export default CommentList;
