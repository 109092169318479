import React, {useEffect, useState} from 'react';
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import Select from "react-select";
import {DateTime} from "luxon";
import {SelectV2} from "../components/forms/FormEl";
import {Badge} from "react-bootstrap";

const formatOptionLabel = ({label, createdAt}) => (
  <div style={{display: "flex"}}>
    <div>{label}</div>
    {
      createdAt &&
      <div style={{marginLeft: "10px"}}>
        <Badge bg="success">{DateTime.fromISO(createdAt).toFormat("LLL-dd HH:mm")}</Badge>
      </div>
    }
  </div>
);

const SelectFirmware = React.forwardRef((props, ref) => {

  const defaultParams = {manual: false, enable: true}
  const {data, loading} = useFetch(Apis.SALAS + '/firmware', [], defaultParams)
  const [selectedOption, setSelectedOption] = useState(null)
  const [optionsFormat, setOptionsFormat] = useState([])

  const prevOnChange = (selected) => {
    props.onChange(selected.value)
  }

  useEffect(() => {
    const selected = optionsFormat.find(option => option.value === props.value)
    setSelectedOption(selected)
  }, [props.value])

  useEffect(() => {
    if (data) {
      const formatted = data.sort((a, b) => b.createdAt.localeCompare(a.createdAt)).map((item) => {
        return {value: item.version, label: item.version, createdAt: item.createdAt};
      });
      setOptionsFormat(formatted)
      if (props.value) {
        const selected = formatted.find(option => option.value === props.value)
        setSelectedOption(selected)
      }
    }
  }, [data])

  return (
    <>
      {
        !loading ?
          <Select
            value={selectedOption}
            onChange={prevOnChange}
            options={optionsFormat}
            formatOptionLabel={formatOptionLabel}
          />
          :
          <SelectV2 disabled options={[{id: 0, name: 'Cargando...'}]}/>
      }
    </>
  );
});

export default SelectFirmware;
