import React, {useEffect} from 'react';
import SelectGroup from "../../Selects/SelectGroup";
import Input from "../../components/forms/FormEl";
import {useForm} from "react-hook-form";
import FormGroup from "../../components/FormGroup";

const PermissionsFormAction = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, watch, reset, control} = useForm();

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  return (
    <>
      <FormGroup>
        <label>Grupo</label>
        <SelectGroup {...register("groupId")} />
      </FormGroup>
      <FormGroup>
        <label>Nombre</label>
        <Input type={'text'} {...register("name")} />
      </FormGroup>
    </>
  );
};

export default PermissionsFormAction;
