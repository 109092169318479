// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lqgTZKrrzVtFq3DSpoSY.lcu4dpknD5bMaa3d1ekM{width:100%;margin-bottom:1rem}.lqgTZKrrzVtFq3DSpoSY.lcu4dpknD5bMaa3d1ekM label{margin-bottom:.2rem;font-weight:600}.lqgTZKrrzVtFq3DSpoSY.giduecLdxjouf4jpF1NV{background-color:#fff;padding:1.8rem;margin-bottom:1rem;box-shadow:var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15));outline:.1rem solid rgba(0,0,0,0)}.lqgTZKrrzVtFq3DSpoSY.axtBY_f86duZKi56I30G{font-size:1.2rem;font-weight:600;margin-bottom:.6rem}`, "",{"version":3,"sources":["webpack://./src/components/form-group/form-group.module.scss"],"names":[],"mappings":"AACE,2CACE,UAAA,CACA,kBAAA,CAEA,iDACE,mBAAA,CACA,eAAA,CAIJ,2CACE,qBAAA,CACA,cAAA,CACA,kBAAA,CAEA,qGAAA,CACA,iCAAA,CAGF,2CACE,gBAAA,CACA,eAAA,CACA,mBAAA","sourcesContent":[".form-group-container {\n  &.default {\n    width: 100%;\n    margin-bottom: 1rem;\n\n    label {\n      margin-bottom: 0.2rem;\n      font-weight: 600;\n    }\n  }\n\n  &.block {\n    background-color: #ffffff;\n    padding: 1.8rem;\n    margin-bottom: 1rem;\n\n    box-shadow: var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, .05), 0 1px 3px 0 rgba(63, 63, 68, .15));\n    outline: .1rem solid transparent;\n  }\n\n  &.title {\n    font-size: 1.2rem;\n    font-weight: 600;\n    margin-bottom: 0.6rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-group-container": `lqgTZKrrzVtFq3DSpoSY`,
	"default": `lcu4dpknD5bMaa3d1ekM`,
	"block": `giduecLdxjouf4jpF1NV`,
	"title": `axtBY_f86duZKi56I30G`
};
export default ___CSS_LOADER_EXPORT___;
