import React, {useEffect} from 'react';
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import axios from "axios";
import ActionGroupForm from "./ActionGroupForm";

const ActionGroupEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch(Apis.USER + `/actions-group/${id}`, []);

  function update(params) {
    return axios.put(Apis.USER + '/actions-group/' + id, params);
  }

  useEffect(() => {
    setTitle('Editar Grupo');
  }, []);

  return (
    <>
      <ActionGroupForm value={data} onSubmit={update} setAction={setAction}/>
    </>
  );
};

export default ActionGroupEdit;
