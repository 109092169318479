import React from 'react';
import useFetch from "../hooks/useFetch";
import { Apis } from "../util/Apis";
import {SelectV2} from "../components/forms/FormEl";

const SelectGroup = React.forwardRef((props, ref) => {

  const { data, loading } = useFetch(Apis.USER + '/actions-group', [])

  const getData = (dataX) => {
    let convertedData = []
    if (dataX) {
      convertedData = dataX.map(item => {
        return {
          id: item.id,
          name: item.name
        }
      })
    }
    return convertedData
  }

  return (
    <>
      {
        !loading &&
        <SelectV2 ref={ref} options={getData(data)} {...props} />
      }
    </>
  )
})

export default SelectGroup;
