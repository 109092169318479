// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label.PvjTCExHA0yCprchYNHP{transition:.5s all ease-in-out;font-family:"Noto Sans",sans-serif;line-height:100%}label.PvjTCExHA0yCprchYNHP.I3LLcFdKmKx_VK1_BEa_{font-size:24px;font-weight:700;margin-bottom:1rem}@media screen and (min-width: 768px){label.PvjTCExHA0yCprchYNHP.I3LLcFdKmKx_VK1_BEa_{font-size:28px;margin-bottom:1.5rem}}label.PvjTCExHA0yCprchYNHP.o4TzRWUgYviP5qLJA0SN{font-size:20px;font-weight:600;margin-bottom:.6rem}`, "",{"version":3,"sources":["webpack://./src/components/title/title.module.scss"],"names":[],"mappings":"AAAA,2BACC,8BAAA,CACA,kCAAA,CACA,gBAAA,CAEA,gDACC,cAAA,CACA,eAAA,CACA,kBAAA,CAEA,qCALD,gDAME,cAAA,CACA,oBAAA,CAAA,CAIF,gDACC,cAAA,CACA,eAAA,CACA,mBAAA","sourcesContent":["label.title {\n\ttransition: 0.5s all ease-in-out;\n\tfont-family: 'Noto Sans', sans-serif;\n\tline-height: 100%;\n\n\t&.page {\n\t\tfont-size: 24px;\n\t\tfont-weight: 700;\n\t\tmargin-bottom: 1rem;\n\n\t\t@media screen and (min-width: 768px) {\n\t\t\tfont-size: 28px;\n\t\t\tmargin-bottom: 1.5rem;\n\t\t}\n\t}\n\n\t&.form {\n\t\tfont-size: 20px;\n\t\tfont-weight: 600;\n\t\tmargin-bottom: 0.6rem;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `PvjTCExHA0yCprchYNHP`,
	"page": `I3LLcFdKmKx_VK1_BEa_`,
	"form": `o4TzRWUgYviP5qLJA0SN`
};
export default ___CSS_LOADER_EXPORT___;
