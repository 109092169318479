import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Table} from "@wargostec/react-components";
import PageSection from "../../components/PageSection";
import PageTitle from "../../components/Layaout/PageTitle";
import {ContainerLoading} from "../SalasList";
import {Loading} from "../../components/forms/Loading";
import GenericModal2 from "../../hooks/GenericModal2";
import PageContainer from "../../components/PageContainer";
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import axios from "axios";
import useEditModal from "../../hooks/useEditModal";
import {Modalverification} from "../../components/forms/ModalVerification";
import {DateTime} from "luxon";
import LudopEdit from "./LudopEdit";
import PageContainerFull from "../../components/PageContainerFull";

const Ludopatas = () => {

  const {loading, data, fetchData} = useFetch(Apis.LUDOP + '/ban-list/5f2c9bc0ccd74b07f0e929d6/members', []);

  const deleteElement = (id) => {
    axios.delete(Apis.LUDOP + '/ban-list-members/' + id)
      .then(res => {
        fetchData()
      })
      .catch(error => {
      })

  }

  const {wrapper: wrapper1, refresh: refresh1 , editAction} = useEditModal({
    editComponent: <LudopEdit/>,
  })


  useEffect(() => {
    fetchData()
  }, [refresh1])

  const actionValue = useCallback((props) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isDelete, setIsDelete] = useState(false);

    return (
      <>
        <div style={{justifyContent:'space-between'}}>
          <Button text={'Editar'} size={'xs'} variant={'success'} onClick={() => editAction(props.renderValue())}/>
          <Button text={'Eliminar'} size={'xs'} variant={'danger'} onClick={() => setIsDelete(true)}/>
          <Modalverification
            show={isDelete}
            handleClose={() => setIsDelete(false)}
            title='¿Seguro que desea eliminar este ludópata?'
            handleDelete={() => deleteElement(props.renderValue())}
          />
        </div>
      </>
    );
  }, []);

  const createdValue = useCallback(props => <>
      {
        <div>
          {props.renderValue() ?
            <span>{DateTime.fromISO(props.renderValue()).toFormat("yyyy'-'LL'-'dd' 'HH':'mm' ")}</span> :
            <span>---</span>}
        </div>
      }
    </>
    , []);


  const columns = useMemo(
    () => [
      {
        header: 'Nombres',
        accessorKey: 'firstName',
      },
      {
        header: 'Apellidos', accessorKey: 'lastName1'
      },
      {
        header: 'DNI', accessorKey: 'document'
      },
      {
        header: 'Dirección', accessorKey: 'location',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Desde',
        accessorKey: 'createdAt',
        cell: createdValue
      },
      {
        header: 'Acciones',
        accessorKey: 'id',
        cell: actionValue
      },
    ]
    , []
  )

  return (
    <PageContainerFull>
      <PageSection>
        <PageTitle title={'Ludopatas'}/>
      </PageSection>

      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <Table columns={columns} data={data} pageSize={100} sortArray={[{id: 'createdAt', desc: true}]} searchable={true}/>
        }
      </PageSection>
      <GenericModal2 {...wrapper1}/>
    </PageContainerFull>
  );
};

export default Ludopatas;
