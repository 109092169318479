import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import PageSection from "../../components/PageSection";
import {Col, FormGroup, Row} from "react-bootstrap";
import SelectTenant from "../../Selects/SelectTenant";

const VersionForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, watch, reset, control} = useForm();

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  return (
    <div>
      <PageSection>
        <Row>
          <Col>
            <FormGroup>
              <label>Version</label>
              <SelectTenant {...register('tenant')} />
            </FormGroup>
          </Col>
        </Row>
      </PageSection>
    </div>
  );
};

export default VersionForm;
