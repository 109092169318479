import React, {useEffect} from 'react';
import useFetch from "../../../hooks/useFetch";
import {Apis} from "../../../util/Apis";
import axios from "axios";
import ServerForm from "./ServerForm";

const ServerEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch(Apis.TGM + '/pangolin-server/' + id);

  function update(params) {
    return axios.put(Apis.TGM + '/pangolin-server/' + id, params);
  }

  useEffect(() => {
    setTitle('Editar servidor');
  }, []);

  return (
    <div>
      {!loading && data &&
        <ServerForm value={data} onSubmit={update} setAction={setAction}/>
      }
    </div>
  );
};

export default ServerEdit;
