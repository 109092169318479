import React from 'react';
import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';
import {Button} from "@wargostec/react-components";

const ModalDiv = styled.div`
  display: ${(p) => p.block && p.block};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 3;
`;
const ContentDiv = styled.div`
  gap: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 20em;
  height: 15em;
  padding: 2rem 2rem 3.5rem 2rem;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 10px;

  .headModal {
    display: flex;
    justify-content: flex-end;
  }
  >h1 {
    text-align: center;
    font-size: 1.5em;
  }
`;
const ContainerButtons = styled.div`
display: flex;
justify-content: space-evenly;
>button {
    width: 100px;
    height: 40px;
    align-items: center;
}
`;

export const Modalverification = ({ handleClose, show, title, handleDelete }) => {
  const handleCloseClick = (e) => e.stopPropagation();
  return (
    <ModalDiv block={show ? 'block' : 'none'} onClick={handleClose}>
      <ContentDiv onClick={handleCloseClick}>
        <div className='headModal'>
          <AiOutlineClose
            fontSize='1.5em'
            cursor='pointer'
            color='black'
            onClick={handleClose}
          />
        </div>
        <h1>
          {title ? title : '¿Esta seguro de eliminar este item?'}
        </h1>
        <ContainerButtons>
          <Button variant='success' onClick={handleDelete} text={'Si'}/>
          <Button variant='danger' onClick={handleClose} text={'No'}/>
        </ContainerButtons>
      </ContentDiv>
    </ModalDiv>
  );
};
