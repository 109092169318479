import React, {useEffect, useState} from 'react';
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import {SelectV2} from "../components/forms/FormEl";
import Select from "react-select";
import {Badge} from "react-bootstrap";

const formatOptionLabel = ({label, tenant}) => (
  <div style={{display: "flex"}}>
    <div>{label}</div>
    {
      tenant &&
      <div style={{marginLeft: "10px"}}>
        <Badge bg="warning" style={{color: 'black'}}>{tenant}</Badge>
      </div>
    }
  </div>
);

const SelectSalas = React.forwardRef((props, ref) => {

  const {data, loading} = useFetch(`${Apis.SALAS}/salas`)
  const [selectedOption, setSelectedOption] = useState(null)
  const [optionsFormat, setOptionsFormat] = useState([])

  const prevOnChange = (selected) => {
    props.onChange(selected.value)
    props.setTenant && props.setTenant(selected.tenant)
  }

  useEffect(() => {
    const selected = optionsFormat.find(option => option.value === props.value)
    setSelectedOption(selected)
  }, [props.value])

  useEffect(() => {
    if (data) {
      const formatted = data.salas.sort((a, b) => a.name.localeCompare(b.name)).map((item) => {
        return {value: item.salaId, label: item.name, tenant: item.tenant};
      });
      setOptionsFormat(formatted)
      if (props.value) {
        const selected = formatted.find(option => option.value === props.value)
        setSelectedOption(selected)
      }
    }
  }, [data])

  return (
    <>
      {
        !loading ?
          <Select
            value={selectedOption}
            onChange={prevOnChange}
            options={optionsFormat}
            formatOptionLabel={formatOptionLabel}
          />
          :
          <SelectV2 disabled options={[{id: 0, name: 'Cargando...'}]}/>
      }
    </>
  )
})

export default SelectSalas;
