import React, { useEffect } from 'react';
import { Apis } from "../util/Apis";
import axios from "axios";
import UserForm from "./UserForm";
import useFetch from "../hooks/useFetch";

const UserEdit = ({ id, setAction, setTitle }) => {

  const { loading, data } = useFetch(Apis.USER + '/internal/users/user-wgt/' + id);

  function update(params) {
    return axios.post(Apis.USER + '/internal/users/' + id + '/change-tenant', params);
  }

  useEffect(() => {
    setTitle('Cambiar tenant a:');
  }, []);

  return (
    <div>
      {
        loading ? <div> Cargando... </div> : <>
          <p style={{ textAlign: 'center' }}>{data?.email}</p>
          <UserForm value={data} onSubmit={update} setAction={setAction} />
        </>
      }
    </div>
  );
};

export default UserEdit;
