import React, {useCallback, useEffect, useMemo, useState} from 'react';
import useFetch from "../../../hooks/useFetch";
import {Apis} from "../../../util/Apis";
import {Button, FlexContainer, Table, Tooltip} from "@wargostec/react-components";
import PageSection from "../../../components/PageSection";
import PageTitle from "../../../components/Layaout/PageTitle";
import {ContainerLoading} from "../../SalasList";
import {Loading} from "../../../components/forms/Loading";
import useEditModal from "../../../hooks/useEditModal";
import {DateTime} from "luxon";
import GenericModal2 from "../../../hooks/GenericModal2";
import ServerEdit from "./ServerEdit";
import ServerNew from "./ServerNew";
import PageContainerFull from "../../../components/PageContainerFull";
import {errorToast, successToast} from "../../../components/Layaout/ToastStyles";
import {LuCopy} from "react-icons/lu";
import {Col, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import SelectSalas from "../../../Selects/SelectSalas";
import {GrClearOption} from "react-icons/gr";
import {useSearchParams} from "react-router-dom";
import {IoAddOutline} from "react-icons/io5";

const ServerList = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const salaIdParam = searchParams.get('salaId') || '';
  const [params, setParams] = useState({salaId: salaIdParam});
  const {data, loading, error, fetchData} = useFetch(Apis.TGM + '/pangolin-server', [], params);
  const {control} = useForm({defaultValues: {salaId: salaIdParam}});

  const {wrapper: wrapper1, refresh: refresh1, addAction, editAction} = useEditModal({
    editComponent: <ServerEdit/>,
    addComponent: <ServerNew/>
  })

  const getData = (params) => {
    setParams(params)
    setSearchParams(params)
  }

  const clear = () => {
    getData({})
  }

  useEffect(() => {
    if (params) {
        fetchData()
    }
  }, [params])

  useEffect(() => {
    fetchData()
  }, [refresh1])

  const actionValue = useCallback(props => <>{
    <div>
      <Button text={'Editar'} size={'xs'} variant={'success'} onClick={() => editAction(props.row.original.id)}/>
    </div>
  }</>, []);

  const createdValue = useCallback(props => <>
      {
        <div>
          {props.renderValue() ?
            <span>{DateTime.fromISO(props.renderValue()).toFormat("yyyy'-'LL'-'dd' 'HH':'mm' ")}</span> :
            <span>---</span>}
        </div>
      }
    </>
    , []);

  const token = useCallback((props) => {
    const renderValue = props.renderValue();

    const copyToClipboard = () => {
      if (renderValue) {
        navigator.clipboard.writeText(renderValue)
          .then(() => {
            successToast('Texto copiado al portapapeles');
          })
          .catch((err) => {
            errorToast('Error al copiar el texto: ', err);
          });
      }
    };

    return (
      <FlexContainer column gap='4px' alignItems='center' justifyContent='center'>
        {renderValue ?
          <>
            <Tooltip text={renderValue} direction='top'>
              {renderValue.substring(0, 6)}
            </Tooltip>
            <Button
              size='xs'
              variant='light'
              icon={<LuCopy size={10}/>}
              style={{padding: '4px'}}
              onClick={copyToClipboard}
            />
          </>
          :
          <span>--</span>
        }

      </FlexContainer>
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'SalaId', accessorKey: 'salaId',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Servidor', accessorKey: 'name',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Pangolin IP', accessorKey: 'serverIp',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Public IP', accessorKey: 'publicIp',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Token',
        accessorKey: 'token',
        cell: token,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        }
      },
      {
        header: 'Creado',
        accessorKey: 'createdAt',
        cell: createdValue,
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        }
      },
      {
        header: 'Acciones',
        cell: actionValue
      },
    ]
    , []
  )

  return (
    <PageContainerFull>
      <PageSection>
        <PageTitle title={'Servidores'}/>
        <FlexContainer column gap='20px'>
          <Row>
            <Col>
              <div style={{width: '300px'}}>
                <Controller
                  name="salaId"
                  control={control}
                  render={({field}) => (
                    <SelectSalas
                      {...field}
                      onChange={(value) => {
                        field.onChange(value);
                        getData({salaId: value});
                      }}
                    />
                  )}
                />
              </div>
            </Col>
            <Col>
              <Button variant={'light'} onClick={addAction} icon={<IoAddOutline/>}/>
            </Col>
            <Col>
              <Button variant={'primary'} onClick={clear} icon={<GrClearOption/>}/>
            </Col>
          </Row>

        </FlexContainer>
      </PageSection>

      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <Table columns={columns} data={data} sortArray={[{id: 'id', asc: false}]} pageSize={20}/>
        }
      </PageSection>

      <GenericModal2 {...wrapper1}/>
    </PageContainerFull>
  );
};

export default ServerList;
