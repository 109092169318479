import React, {useEffect, useState} from 'react';
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import PageSection from "../components/PageSection";
import PageTitle from "../components/Layaout/PageTitle";
import {ContainerLoading} from "./SalasList";
import GenericModal2 from "../hooks/GenericModal2";
import useEditModal from "../hooks/useEditModal";
import {Button} from "@wargostec/react-components";
import {Modalverification} from "../components/forms/ModalVerification";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import ActionEndpointEdit from "./actions-endpoints/ActionEndpointEdit";
import ActionEndpointNew from "./actions-endpoints/ActionEndpointNew";
import NoDataTable from "../components/NoDataTable";
import {Preview} from "../components/forms/Preview";
import {Loading} from "../components/forms/Loading";
import {Table} from "../components/forms/Table";

const ActionsEndpointsList = () => {

  const {actionId} = useParams()

  const {data, loading, fetchData, error} = useFetch(Apis.USER + `/actions-endpoints?actionId=${actionId}`, [])

  const deleteElement = (id) => {
    axios.delete(Apis.USER + '/actions-endpoints/' + id)
      .then(res => {
        fetchData()
      })
      .catch(error => {
      })

  }

  const {wrapper: wrapper1, refresh: refresh1, addAction, editAction} = useEditModal({
    editComponent: <ActionEndpointEdit/>,
    addComponent: <ActionEndpointNew/>
  })

  const columns = [
    {Header: 'ID', accessor: 'id'},
    {Header: 'Endpoint', accessor: 'endpoint'},
    {
      Header: 'Acciones',
      Cell: ({cell}) => {
        const [isDelete, setIsDelete] = useState(false);
        return (
          <div style={{justifyContent: 'space-evenly'}}>
            <Button text={'Editar'} size={'xs'} variant={'success'}
                    onClick={() => editAction(cell.row.original.id)}/>
            <Button text={'Eliminar'} size={'xs'} variant={'danger'} onClick={() => setIsDelete(true)}/>
            <Modalverification
              show={isDelete}
              handleClose={() => setIsDelete(false)}
              title='¿Seguro que desea eliminar este action endpoint?'
              handleDelete={() => deleteElement(cell.row.original.id)}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchData()
  }, [refresh1])

  const navigate = useNavigate();

  return (
    <>
      <PageSection>
        <Preview text='Regresar' onClick={() => navigate(-1)}/>
        <PageTitle title={data ? `Endpoints : ${data.actionName}` : ''}/>
        <Button text={'Nuevo'} variant={'primary'} onClick={addAction}/>
      </PageSection>

      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <div>
              {
                data.data && data.data.length > 0 ?
                  <Table columns={columns} data={data.data && data.data}/>
                  :
                  <NoDataTable text={"No existen endpoints privatizado aun"}/>
              }
            </div>

        }
      </PageSection>
      <GenericModal2  {...wrapper1}/>
    </>
  );
};

export default ActionsEndpointsList;
