import React, {useCallback, useEffect, useMemo, useState} from 'react';
import useFetch from "../../../hooks/useFetch";
import {Apis} from "../../../util/Apis";
import axios from "axios";
import useEditModal from "../../../hooks/useEditModal";
import PangolinLoteEdit from "./PangolinLoteEdit";
import PangolinLoteNew from "./PangolinLoteNew";
import {Button, Table} from "@wargostec/react-components";
import {Modalverification} from "../../../components/forms/ModalVerification";
import {DateTime} from "luxon";
import PageSection from "../../../components/PageSection";
import PageTitle from "../../../components/Layaout/PageTitle";
import {ContainerLoading} from "../../SalasList";
import {Loading} from "../../../components/forms/Loading";
import GenericModal2 from "../../../hooks/GenericModal2";
import PageContainer from "../../../components/PageContainer";

const PangolinLoteList = () => {

  const {loading, data, fetchData} = useFetch(Apis.TGM + '/pangolin-lote', []);

  const deleteElement = (id) => {
    axios.delete(Apis.TGM + '/pangolin-lote/' + id)
      .then(res => {
        fetchData()
      })
      .catch(error => {
      })
  }

  const {wrapper: wrapper1, refresh: refresh1, addAction, editAction} = useEditModal({
    editComponent: <PangolinLoteEdit/>,
    addComponent: <PangolinLoteNew/>
  })

  const actionValue = useCallback(props => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isDelete, setIsDelete] = useState(false);
    const id = props.row.original.id;
    return (
      <>
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <Button text={'Editar'} size={'xs'} variant={'success'} onClick={() => editAction(id)}/>
          <Button text={'Eliminar'} size={'xs'} variant={'danger'} onClick={() => setIsDelete(true)}/>
          <Modalverification
            show={isDelete}
            handleClose={() => setIsDelete(false)}
            title='¿Seguro que desea eliminar este lote?'
            handleDelete={() => deleteElement(id)}
          />
        </div>
      </>
    );
  }, []);

  const createdValue = useCallback(props => <>
      {
        <div>
          {props.renderValue() ?
            <span>{DateTime.fromISO(props.renderValue()).toFormat("yyyy'-'LL'-'dd' 'HH':'mm' ")}</span> :
            <span>---</span>}
        </div>
      }
    </>
    , []);

  const columns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Cantidad',
        accessorKey: 'quantity',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Version de placa',
        accessorKey: 'boardVersion',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Fecha de creación',
        accessorKey: 'createdAt',
        cell: createdValue,
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Acciones',
        cell: actionValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
    ]
    , []
  )


  useEffect(() => {
    fetchData()
  }, [refresh1])

  return (
    <PageContainer>
      <PageSection>
        <PageTitle title={'Lotes'}/>
      </PageSection>

      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <Table columns={columns} data={data} pageSize={100} sortArray={[{id: 'createdAt', desc: true}]}
                   searchable={true} onAdd={addAction}/>
        }
      </PageSection>
      <GenericModal2 {...wrapper1}/>
    </PageContainer>
  );
};

export default PangolinLoteList;
