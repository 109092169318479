import React, {useEffect} from 'react';
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import axios from "axios";
import GameForm from "./GameForm";
import PageSection from "../../components/PageSection";

const GameEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch(Apis.SALAS + '/slot-model/' + id + "/game")

  const update = async (params) => {
    await axios.put(Apis.SALAS + '/slot-model/' + id + "/update-game", params)
  }

  useEffect(() => {
    setTitle("Edit de juego")
  }, [])

  return (
    <PageSection>
      {
        data && !loading &&
        <GameForm value={data} onSubmit={update} setAction={setAction} />
      }
    </PageSection>
  );
};

export default GameEdit;
