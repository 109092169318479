import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarLeftExpand } from 'react-icons/tb'
import { FaBars } from 'react-icons/fa'
import Sidebar from '../sidebar'
import ViewContent from '../view-content'
import { useToggle } from '../../hooks'
import styles from './layout.module.scss'

type LayoutProps = {
  brand?: string
  logo?: string
  defaultOpen?: boolean
  items?: React.ReactNode
  menus?: React.ReactNode
  header?: React.ReactNode
  children?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ logo, brand, items, menus, header, children, defaultOpen = false }) => {
  const { isOpen, toggler } = useToggle({
    defaultValue: defaultOpen
  });

  return (
    <>
      <Sidebar
        logo={logo}
        isOpen={isOpen}
        items={items}
        menus={menus}
        brand={brand}
      />
      <div className={`${styles['layout-backdrop']} ${isOpen ? styles['open'] : ''}`} onClick={toggler}></div>
      <ViewContent isOpen={isOpen}>
        <header className={`${styles['header-container']}`}>
          <div className={`${styles['toggler']}`} onClick={toggler}>
            {isOpen ?
              <TbLayoutSidebarLeftCollapse size={24} />
              :
              <TbLayoutSidebarLeftExpand size={24} />
            }
          </div>
          <div className={`${styles['hamburguer']}`} onClick={toggler}>
            <FaBars size={18} />
          </div>
          <div>
            {header}
          </div>
        </header>
        <div className={`${styles['content-container']}`}>
          {children}
        </div>
      </ViewContent>
    </>
  )
}

export default Layout