import React from 'react';
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import {SelectV2} from "../components/forms/FormEl";

const SelectMaker = React.forwardRef((props, ref) => {

  const {data, loading} = useFetch(`${Apis.SALAS}/manufacturer`)

  const getData = (dataX) => {
    let convertedData = []
    if (dataX) {
      convertedData = dataX.map(item => {
        return {
          id: item.manufacturerId,
          name: item.name
        }
      })
    }

    return convertedData
  }

  return (
    <>
      {
        !loading ?
          <SelectV2 ref={ref} options={getData(data)} {...props}/>
          :
          <SelectV2 disabled options={[{id: 0, name: 'Seleccione...'}]}/>
      }
    </>
  )
})

export default SelectMaker;
