import styles from './form-group.module.scss'

type FormGroupProps = {
  children?: React.ReactNode
  type?: 'default' | 'block' | 'title'
}

const FormGroup: React.FC<FormGroupProps> = ({ children, type = "default" }) => {
  return (
    <div className={`${styles['form-group-container']} ${styles[type]}`}>
      {children}
    </div>
  )
}

export default FormGroup