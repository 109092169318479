// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iMUZ7zjDEqRs7NNcY37W{display:flex;justify-content:center;width:fit-content;padding:2rem;flex-direction:column;align-items:center;gap:1rem}.iMUZ7zjDEqRs7NNcY37W>span{user-select:none;font-size:16px;font-family:"Noto Sans",sans-serif}`, "",{"version":3,"sources":["webpack://./src/components/loader/loader.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,QAAA,CAEA,2BACE,gBAAA,CACA,cAAA,CACA,kCAAA","sourcesContent":[".loader-container {\n  display: flex;\n  justify-content: center;\n  width: fit-content;\n  padding: 2rem;\n  flex-direction: column;\n  align-items: center;\n  gap: 1rem;\n\n  &>span {\n    user-select: none;\n    font-size: 16px;\n    font-family: 'Noto Sans', sans-serif;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader-container": `iMUZ7zjDEqRs7NNcY37W`
};
export default ___CSS_LOADER_EXPORT___;
