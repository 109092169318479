import React from 'react'
import Button from '../button'

export type TabProps = {
  title?: string
  isActive?: boolean
  children?: React.ReactNode
  onClick?: () => void
}

const Tab: React.FC<TabProps> = ({ title = '', isActive = false, onClick }) => {
  return (
    <Button
      role="tab"
      size='md'
      variant={isActive ? 'success' : 'light'}
      aria-selected={isActive}
      onClick={onClick}
      text={title}
    />
  )
}

export default Tab