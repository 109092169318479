import React, {useEffect} from "react";
import {useWauth} from "../auth/WauthProvider";

const LogoutPage = () => {

  localStorage.clear();

  const {logout} = useWauth()

  useEffect(() => {
    logout({returnTo: window.location.origin})
  }, [])

  return (
    <div>Loading...</div>
  )
}

export default LogoutPage
