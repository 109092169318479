import React, {useEffect, useState} from 'react';
import PageSection from "../components/PageSection";
import {ContainerLoading} from "./SalasList";
import {Button} from "@wargostec/react-components";
import {Modalverification} from "../components/forms/ModalVerification";
import axios from "axios";
import {Apis} from "../util/Apis";
import useFetch from "../hooks/useFetch";
import MakerEdit from "./MakerEdit";
import MakerNew from "./MakerNew";
import useEditModal from "../hooks/useEditModal";
import GenericModal2 from "../hooks/GenericModal2";
import PageTitle from "../components/Layaout/PageTitle";
import {Loading} from "../components/forms/Loading";
import {Table} from "../components/forms/Table";

const MakerList = () => {

  const {loading, data, fetchData} = useFetch(Apis.SALAS + '/manufacturer', []);

  const deleteElement = (id) => {
    axios.delete(Apis.SALAS + '/manufacturer/' + id)
      .then(res => {
        fetchData()
      })
      .catch(error => {
      })

  }

  const {wrapper: wrapper1, refresh: refresh1, addAction, editAction} = useEditModal({
    editComponent: <MakerEdit/>,
    addComponent: <MakerNew/>
  })

  useEffect(() => {
    fetchData()
  }, [refresh1])

  const columns = [
    {Header: 'Id', accessor: 'manufacturerId'},
    {Header: 'Nombre', accessor: 'name'},
    {
      Header: 'Logo',
      accessor: 'logoURL',
      Cell: ({row}) => {
        return (
          <img src={`https://wgt-tmp-uploads.s3.amazonaws.com/${row.values.logoURL}`} alt={'Marker'}
               style={{width: "100px"}}/>
        );
      }
    },
    {
      Header: 'Acciones',
      Cell: ({cell}) => {
        const [isDelete, setIsDelete] = useState(false);
        return (
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <Button text={'Editar'} size={'xs'} variant={'success'} onClick={() => editAction(cell.row.original.id)}/>
            <Button text={'Eliminar'} size={'xs'} variant={'danger'} onClick={() => setIsDelete(true)}/>
            <Modalverification
              show={isDelete}
              handleClose={() => setIsDelete(false)}
              title='¿Seguro que desea eliminar este fabricante?'
              handleDelete={() => deleteElement(cell.row.original.id)}
            />
          </div>
        );
      },
    },

  ];

  return (
    <>
      <PageSection>
        <PageTitle title={'Fabricantes'}/>
        <Button text={'Nuevo'} variant={'primary'} onClick={addAction}/>
      </PageSection>

      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <Table columns={columns} data={data}/>
        }
      </PageSection>
      <GenericModal2 {...wrapper1}/>
    </>
  );
};

export default MakerList;
