import React, {useEffect} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import FormGroup from "../components/forms/FormGroup";
import {Button, Card, Input, Select} from "@wargostec/react-components";
import {errorToast, successToast} from "../components/Layaout/ToastStyles";
import axios from "axios";
import {Apis} from "../util/Apis";
import {Controller, useForm} from "react-hook-form";
import PageSection from "../components/PageSection";
import PageTitle from "../components/Layaout/PageTitle";
import SelectSalas from "../Selects/SelectSalas";
import MachineSelectV2 from "../Selects/MachineSelectV2";
import useFetch from "../hooks/useFetch";

const CreateTicket = () => {

  const {register, control, handleSubmit, reset, formState: {errors}, setValue, watch} = useForm({});

  const watchSalaId  = watch('salaId')

  const {data, loading,fetchData} = useFetch(`${Apis.TGM}/machines/combo`,{},{},{"X-Sala-ID": watchSalaId },false)

  useEffect(() => {
    if (watchSalaId){
      fetchData()
    }
  }, [watchSalaId]);

  const onCreate = (params) => {
    params.amount = params.amount * 100
    axios.post(Apis.TICKET + '/ticket-by-caja/create', params, {headers: {"X-Sala-ID": watchSalaId}})
      .then(res => {
        successToast("Operación realizada correctamente", 2000)
        clear()
      }).catch(err => {
      errorToast("Ocurrió un problema al guardar. Intente de nuevo en unos minutos.", 2000)
    })
  }

  const clear = () => {
    reset({
      vn: "",
      confId: "",
      amount: "",
      date: "",
      time: "",
      validationType: 0,
      validationSystemId: 0,
    });
  };


  return (
    <>
      <PageSection>
        <PageTitle title={'Crear ticket'}/>
      </PageSection>
      <PageSection>
        <Card padding>
          <Form>
            <Row>
              <Col>
                <FormGroup>
                  <label>Sala</label>
                  <Controller
                    name="salaId"
                    control={control}
                    render={({field}) => (
                      <SelectSalas
                        {...field}
                      />
                    )}
                  />
                </FormGroup>
              </Col>
              <Col>
                {
                  watchSalaId &&
                  <FormGroup>
                    <label>Maquina</label>
                    <Controller
                      name="confId"
                      control={control}
                      render={({field}) => <MachineSelectV2 {...field} salaId={watchSalaId} data={data}/>}
                    />
                  </FormGroup>
                }
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <label>Numero de ticket</label>
                  <Input type="number" {...register("vn", {required: "Campo obligatorio"})}/>
                  {errors.vn && <p style={{color: 'red'}}>{errors.vn?.message}</p>}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label>Monto (S/.)</label>
                  <Input type="number" {...register("amount")}/>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <label>Fecha</label>
                  <Input type="date" {...register("date", {required: "Campo obligatorio"})}/>
                  {errors.date && <p style={{color: 'red'}}>{errors.date?.message}</p>}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label>Hora</label>
                  <Input type="time" {...register("time", {required: "Campo obligatorio"})}/>
                  {errors.time && <p style={{color: 'red'}}>{errors.time?.message}</p>}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <label>Validacion del sistema</label>
                  <Select {...register("validationSystemId")}>
                    <option key={1} value={0}>0</option>
                    <option key={2} value={1}>1</option>
                  </Select>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label>Tipo de ticket</label>
                  <Select {...register("validationType")}>
                    <option key={1} value={0}>CANJEABLE</option>
                    <option key={2} value={1}>PROMOCIONAL</option>
                  </Select>
                </FormGroup>
              </Col>
            </Row>

          </Form>
          <Button text={"Guardar"} variant={'primary'} onClick={handleSubmit(onCreate)}/>
        </Card>
      </PageSection>
    </>
  );
};

export default CreateTicket;
