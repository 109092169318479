import styles from './card.module.scss'

type CardProps = {
  children?: React.ReactNode
  backgroundColor?: string
  noPadding?: boolean
  onClick?: () => void
}

const Card: React.FC<CardProps> = ({ children, backgroundColor, onClick, noPadding = false }) => {
  return (
    <div className={`${styles['card-container']} ${noPadding ? styles['no-padding'] : ''}`} onClick={onClick} style={{ cursor: onClick ? 'pointer' : 'auto', backgroundColor }}>
      {children}
    </div>
  )
}

export default Card