import styles from './avatar.module.scss'

type AvatarProps = {
  name?: string
  onClick?: () => void
}

const Avatar: React.FC<AvatarProps> = ({name = '', onClick = () => {}}) => {
  return (
    <button onClick={onClick} className={`${styles['avatar-container']}`}>{name.substring(0, 3)}</button>
  )
}

export default Avatar