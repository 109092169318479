import React from 'react';

export const HiOutlineSlotMachine = ({ size = 18 }) => {
  return (
    <svg viewBox="0 0 24 24" width={size} height={size} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 12L7 8V7H4V8H6L4 12M9 12L11 8V7H8V8H10L8 12M13 12L15 8V7H12V8H14L12 12M21 2C19.9 2 19 2.9 19 4C19 4.7 19.4 5.4 20 5.7V17H17V15C17.6 15 18 14.6 18 14V5C18 4.4 17.6 4 17 4H13.2C12.4 2.8 11 2 9.5 2C8 2 6.6 2.8 5.8 4H2C1.4 4 1 4.4 1 5V14C1 14.6 1.4 15 2 15V22H17V19H20C21.1 19 22 18.1 22 17V5.7C22.6 5.4 23 4.7 23 4C23 2.9 22.1 2 21 2ZM3 6H16V13H3V6ZM15 20H4V15H15V20ZM13 19H6V17H13V19Z"/>
    </svg>
  );
};

export const HiSlotMachine = ({ size = 18 }) => {
  return (
    <svg viewBox="0 0 24 24" width={size} height={size} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.40063 12.9329L7.40063 8.93286V7.93286H4.40063V8.93286H6.40063L4.40063 12.9329M9.40063 12.9329L11.4006 8.93286V7.93286H8.40063V8.93286H10.4006L8.40063 12.9329M13.4006 12.9329L15.4006 8.93286V7.93286H12.4006V8.93286H14.4006L12.4006 12.9329M21.4006 2.93286C20.3006 2.93286 19.4006 3.83286 19.4006 4.93286C19.4006 5.63286 19.8006 6.33286 20.4006 6.63286V17.9329H17.4006V15.9329C18.0006 15.9329 18.4006 15.5329 18.4006 14.9329V5.93286C18.4006 5.33286 18.0006 4.93286 17.4006 4.93286H13.6006C12.8006 3.73286 11.4006 2.93286 9.90063 2.93286C8.40063 2.93286 7.00063 3.73286 6.20063 4.93286H2.40063C1.80063 4.93286 1.40063 5.33286 1.40063 5.93286V14.9329C1.40063 15.5329 1.80063 15.9329 2.40063 15.9329V22.9329H17.4006V19.9329H20.4006C21.5006 19.9329 22.4006 19.0329 22.4006 17.9329V6.63286C23.0006 6.33286 23.4006 5.63286 23.4006 4.93286C23.4006 3.83286 22.5006 2.93286 21.4006 2.93286ZM13.4006 19.9329H6.40063V17.9329H13.4006V19.9329ZM16.4006 13.9329H3.40063V6.93286H16.4006V13.9329Z"/>
    </svg>
  );
};