import React, {useState} from "react";

const useEditModal = ({editComponent, addComponent}) => {

  const [refresh, setRefresh] = useState(1)
  const [showModal, setShowModal] = useState(false);

  const [body, setBody] = useState(null);

  const addAction = () => {
    setShowModal(true)
    //setMode('new')

    setBody(addComponent)
  }

  const editAction = (id) => {
    setShowModal(true)

    setBody(React.cloneElement(editComponent, {id}))
  }

  //TODO, incluir parametro para ver si debemos refrecar o no
  const onCloseModal = () => {
    setShowModal(false)
    setRefresh(refresh + 1)
  }

  const wrapper = {
    show: showModal,
    onHide: onCloseModal,
    body: body
  }

  return {wrapper, refresh, addAction, editAction}
}

export default useEditModal


//uso anterior
//<GenericModal2 {...wrapper1}
// body={
//   mode === 'new' ? <ZoneNew/> : <ZoneEdit id={id}/>
// }
///>
