import { FaChevronLeft } from 'react-icons/fa';
import React from 'react';
import styled from 'styled-components';

const PreviewStyled = styled.span`
  cursor: pointer;
  font-weight: normal;
  color: rgb(99, 115, 129);
  padding: 0.6rem 0;
  display: flex;
  align-items: center;

  &:hover {
    color: #212b36;
    text-decoration: none;
  }

  span {
    margin-left: 0.3rem;
    user-select: none;
  }
`;

export function Preview({ text, onClick }) {
  return (
    <PreviewStyled onClick={onClick}>
      <FaChevronLeft />
      <span>{text}</span>
    </PreviewStyled>
  );
}
