import React, {useEffect} from 'react';
import {Col, Row} from "react-bootstrap";
import FormGroup from "../components/FormGroup";
import SelectTenant from "../Selects/SelectTenant";
import PageSection from "../components/PageSection";
import {Controller, useForm} from "react-hook-form";

const UserForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset, control} = useForm()

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  return (
    <div>
      <PageSection>
        <Row>
          <Col>
            <FormGroup>
              <label>Tenant</label>
              <Controller
                name="tenant"
                control={control}
                render={({field}) => <SelectTenant {...field} />}
              />
            </FormGroup>
          </Col>
        </Row>
      </PageSection>
    </div>
  );
};

export default UserForm;
