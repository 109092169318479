// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Gj5W1n1cu5I0uNieuqcK{display:flex;width:fit-content;flex-direction:row;align-items:center;gap:6px}.Gj5W1n1cu5I0uNieuqcK>input[type=checkbox]{width:34px;height:18px;margin-top:0px;transition:.5s all ease-in-out}.Gj5W1n1cu5I0uNieuqcK>input[type=checkbox]:checked{transition:.5s all ease-in-out;background-color:#0a0a0a;border:rgba(0,0,0,0)}.Gj5W1n1cu5I0uNieuqcK>input[type=checkbox]:focus{border:rgba(0,0,0,0);outline:0;box-shadow:none}.Gj5W1n1cu5I0uNieuqcK>label{user-select:none;font-family:"Noto Sans",sans-serif;font-size:14px}`, "",{"version":3,"sources":["webpack://./src/components/checkbox/checkbox.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,iBAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CAEA,2CACE,UAAA,CACA,WAAA,CACA,cAAA,CACA,8BAAA,CAEA,mDACE,8BAAA,CACA,wBAAA,CACA,oBAAA,CAGF,iDACE,oBAAA,CACA,SAAA,CACA,eAAA,CAIJ,4BACE,gBAAA,CACA,kCAAA,CACA,cAAA","sourcesContent":[".checkbox-container {\n  display: flex;\n  width: fit-content;\n  flex-direction: row;\n  align-items: center;\n  gap: 6px;\n\n  & > input[type='checkbox'] {\n    width: 34px;\n    height: 18px;\n    margin-top: 0px;\n    transition: 0.5s all ease-in-out;\n\n    &:checked {\n      transition: 0.5s all ease-in-out;\n      background-color: #0A0A0A;\n      border: transparent;\n    }\n\n    &:focus {\n      border: transparent;\n      outline: 0;\n      box-shadow: none;\n    }\n  }\n\n  & > label {\n    user-select: none;\n    font-family: 'Noto Sans', sans-serif;\n    font-size: 14px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-container": `Gj5W1n1cu5I0uNieuqcK`
};
export default ___CSS_LOADER_EXPORT___;
