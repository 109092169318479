import React from 'react'
import styles from './tab-content.module.scss'

type TabContentProps = {
  children?: React.ReactNode
  isActive?: boolean
}

const TabContent: React.FC<TabContentProps> = ({ children, isActive = false }) => {
  return (
    <div className={`${styles['tab-content-container']} ${isActive ? styles['active'] : ''}`}>
      {children}
    </div>
  )
}

export default TabContent