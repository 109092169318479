import styles from './title.module.scss'

type TitleProps = {
  text?: string;
  type?: 'page' | 'form';
}

const Title: React.FC<TitleProps> = ({text, type = 'page'}) => {
  return <label className={`${styles['title']} ${styles[type]}`}>{text}</label>;
};

export default Title;
