// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.u3j_3ET5X89WTHGOiKvk{position:relative;display:flex;flex-direction:column;align-items:center;justify-content:center;border:2px dashed #0a0a0a;color:#0a0a0a;border-radius:8px;font-size:13px;font-family:"Noto Sans",sans-serif;padding:8px;cursor:pointer;z-index:10}.u3j_3ET5X89WTHGOiKvk>div{display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center;height:100%;gap:4px}.u3j_3ET5X89WTHGOiKvk:hover{background:#f5f5f5}.OzAFCtdabMTGMa_M0kH8{cursor:pointer;position:absolute;top:4px;right:4px;border-radius:16px;z-index:90}.OzAFCtdabMTGMa_M0kH8:hover{background-color:#fff;color:#991b1b}`, "",{"version":3,"sources":["webpack://./src/components/file-uploader/file-uploader.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,yBAAA,CACA,aAAA,CACA,iBAAA,CACA,cAAA,CACA,kCAAA,CACA,WAAA,CACA,cAAA,CACA,UAAA,CAEA,0BACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CACA,WAAA,CACA,OAAA,CAGF,4BACE,kBAAA,CAIJ,sBACE,cAAA,CACA,iBAAA,CACA,OAAA,CACA,SAAA,CACA,kBAAA,CACA,UAAA,CACA,4BACE,qBAAA,CACA,aAAA","sourcesContent":[".file-uploader-container {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  border: 2px dashed #0A0A0A;\n  color: #0A0A0A;\n  border-radius: 8px;\n  font-size: 13px;\n  font-family: 'Noto Sans', sans-serif;\n  padding: 8px;\n  cursor: pointer;\n  z-index: 10;\n\n  &>div {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    height: 100%;\n    gap:4px;\n  }\n\n  &:hover {\n    background:#f5f5f5;\n  }\n}\n\n.file-uploader-close{\n  cursor:pointer;\n  position:absolute;\n  top: 4px;\n  right:4px;\n  border-radius: 16px;\n  z-index: 90;\n  &:hover {\n    background-color: white;\n    color:#991b1b;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file-uploader-container": `u3j_3ET5X89WTHGOiKvk`,
	"file-uploader-close": `OzAFCtdabMTGMa_M0kH8`
};
export default ___CSS_LOADER_EXPORT___;
