// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w1fP3DnBNlrcZuKPrrAZ{--bs-gutter-x: 1.5rem;--bs-gutter-y: 0;display:flex;flex-wrap:wrap;margin-top:calc(-1*var(--bs-gutter-y));margin-right:calc(-0.5*var(--bs-gutter-x));margin-left:calc(-0.5*var(--bs-gutter-x))}`, "",{"version":3,"sources":["webpack://./src/components/row/row.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,gBAAA,CACA,YAAA,CACA,cAAA,CACA,sCAAA,CACA,0CAAA,CACA,yCAAA","sourcesContent":[".row-container {\n  --bs-gutter-x: 1.5rem;\n  --bs-gutter-y: 0;\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: calc(-1 * var(--bs-gutter-y));\n  margin-right: calc(-.5 * var(--bs-gutter-x));\n  margin-left: calc(-.5 * var(--bs-gutter-x));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row-container": `w1fP3DnBNlrcZuKPrrAZ`
};
export default ___CSS_LOADER_EXPORT___;
