// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.JNdc9U1_kYFtAdoH5XWs{background:linear-gradient(rgb(82, 97, 221), rgb(19, 181, 234));color:#fff;border-color:#069cf9;height:32px;width:32px;border-radius:100%;display:flex;align-items:center;justify-content:center;font-size:14px;user-select:none;border:none;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/avatar/avatar.module.scss"],"names":[],"mappings":"AAAA,4BACC,+DAAA,CACA,UAAA,CACA,oBAAA,CACA,WAAA,CACA,UAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,gBAAA,CACA,WAAA,CACC,cAAA","sourcesContent":["button.avatar-container {\n\tbackground: linear-gradient(rgb(82, 97, 221), rgb(19, 181, 234));\n\tcolor: rgb(255, 255, 255);\n\tborder-color: #069cf9;\n\theight: 32px;\n\twidth: 32px;\n\tborder-radius: 100%;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tfont-size: 14px;\n\tuser-select: none;\n\tborder: none;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar-container": `JNdc9U1_kYFtAdoH5XWs`
};
export default ___CSS_LOADER_EXPORT___;
