import styles from './sidebar-footer.module.scss';

type SidebarFooterProps = {
  children?: React.ReactNode
}

const SidebarFooter: React.FC<SidebarFooterProps> = ({children}) => {
  return (
    <div className={`${styles['sidebar-footer']}`}>
      {children}
    </div>
  )
}

export default SidebarFooter