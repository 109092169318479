import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../util/Apis";
import PageCardTitle from "../../components/Layaout/CardTitle";
import FirmwareForm from "./FirmwareForm";

const FirmwareNew = ({setAction, setTitle}) => {

  const create = async (params) => {
    params.manual = true
    await axios.post(Apis.SALAS + '/firmware', params).catch((err) => {
    });
  }

  useEffect(() => {
    setTitle("Nuevo firmware");
  }, []);

  return (
    <>
      <PageCardTitle>
        Nuevo firmware
      </PageCardTitle>
      <FirmwareForm value={{}} onSubmit={create} setAction={setAction}/>
    </>
  );
};

export default FirmwareNew;
