import styles from './sidebar-item.module.scss';

type SidebarItemProps = {
  active?: boolean
  icon?: React.ReactNode
  onClick?: () => void
}

const SidebarItem: React.FC<SidebarItemProps> = ({icon, active = false, onClick = () => {}}) => {
  return (
    <button className={`${styles['sidebar-item']} ${active ? styles['active'] : ''}`} onClick={onClick}>{icon}</button>
  )
}

export default SidebarItem