import React, {useEffect} from 'react';
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import axios from "axios";
import LudopForm from "./LudopForm";

const LudopEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch(Apis.LUDOP + '/ban-list-members/' + id);

  function update(params) {
    params.id = id
    return axios.put(Apis.LUDOP + '/ban-list-members/' + id, params);
  }

  useEffect(() => {
    setTitle('Editar ludópata');
  }, []);

  return (
    <div>
      {!loading && data &&
        <LudopForm value={data} onSubmit={update} setAction={setAction}/>
      }
    </div>
  );
};

export default LudopEdit;
