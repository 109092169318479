import React from 'react'
import styled from "styled-components";
import {NavLink} from "react-router-dom";

const MenuItemSpan = styled.span`
    display: block;
    padding:8px 12px;
    color: #fff;
    text-decoration: none;
    background: transparent;
    font-size: 0.85rem;
    font-weight: 400;
    transition: color 100ms linear 0s;
    
    &:hover{
      background:#404040;
      border-radius:6px;
    }
`

const MenuItem = ({title, path, onClick}) => {
  return (
    <li>
      <NavLink 
        to={path} 
        className={({ isActive, isPending }) =>
          isPending ? "pending" : isActive ? "myActiveLink" : ""
        } 
        onClick={onClick}
      >
        <MenuItemSpan>{title}</MenuItemSpan>
      </NavLink>
    </li>
  )
}

export default MenuItem
