import {BsX} from "react-icons/bs";
import styles from './slidebar.module.scss'

type SlidebarProps = {
  title?: string
  isOpen?: boolean
  toggler?: (props: any) => void
  children?: React.ReactNode
}

const Slidebar: React.FC<SlidebarProps> = ({title = 'Filtros', isOpen, children, toggler}) => {
  return (
    <div className={`${styles['slidebar-content']} ${!isOpen ? styles['close'] : ''}`}>
      <div className={`${styles['header']}`}>
        <span>{title}</span>
        <div onClick={toggler}>
          <BsX size={32} />
        </div>
      </div>
      <div className={`${styles['content']}`}>
        {children}
      </div>
    </div>
  )
}

export default Slidebar