import React, {useCallback, useMemo} from 'react';
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import PageSection from "../../components/PageSection";
import PageTitle from "../../components/Layaout/PageTitle";
import {Button, Table} from "@wargostec/react-components";
import {Loading} from "../../components/forms/Loading";
import PageContainerFull from "../../components/PageContainerFull";
import {ContainerLoading} from "../SalasList";
import {MdFiberManualRecord} from "react-icons/md";
import {useNavigate} from "react-router-dom";

const BillConfig = () => {

  const {loading, data} = useFetch(Apis.SALAS + '/salas', []);
  const navigate = useNavigate();

  const billingValue = useCallback(props => <>
      {
        <div>
          {props.renderValue() ?
            <MdFiberManualRecord color={'#60FC1C'} size={28}/> :
            <MdFiberManualRecord color={'#bdb6b5'} size={28}/>}
        </div>
      }
    </>
    , []);

  const billingPlan = useCallback(props => <>
      {
        <div>
          {props.renderValue() === 'coupon' ? 'Cupones' : props.renderValue() === 'standard' ? 'Pro' : props.renderValue() === 'premium' ? 'Premium' : '---'}
        </div>
      }
    </>
    , []);

  const billingPaymentCycle = useCallback(props => <>
      {
        <div>
          {props.renderValue() === 'monthly' ? 'Mensual' : props.renderValue() === 'yearly' ? 'Anual' : '---'}
        </div>
      }
    </>
    , []);

  function goEditSalas(id) {
    navigate("/salas-edit/" + id + '?tab=billing');
  }

  const actionValue = useCallback(props => {
    return (
      <>
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <Button text={'Editar'} size={'xs'} variant={'success'} onClick={() => goEditSalas(props.renderValue())}/>
        </div>
      </>
    );
  }, []);

  const zeroValue = useCallback(props => {
    return (
      <>
        {
          props.renderValue() === 0 ? '---' : props.renderValue()
        }
      </>
    );
  }, []);

  const feeValue = useCallback(props => {
    return (
      <>
        {
          props.renderValue() === 0 ? '---' : (props.renderValue() / 100).toFixed(2)
        }
      </>
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        header: 'SalaID',
        accessorKey: 'salaId',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Nombre',
        accessorKey: 'name',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Habilitado',
        accessorKey: 'billing.active',
        cell: billingValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'RUC',
        accessorKey: 'billing.ruc',
        cell: zeroValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Cantidad de maquinas',
        accessorKey: 'billing.machinesQuantity',
        cell: zeroValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Plan',
        accessorKey: 'billing.plan',
        cell: billingPlan,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Pago',
        accessorKey: 'billing.paymentCycle',
        cell: billingPaymentCycle,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Costo x maquina',
        accessorKey: 'billing.fee',
        cell: feeValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Correo',
        accessorKey: 'billing.email',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Dias de retraso',
        accessorKey: 'daysLate',
        cell: zeroValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Acciones',
        accessorKey: 'id',
        cell: actionValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
    ]
    , []
  )

  return (
    <PageContainerFull>
      <PageSection>
        <PageTitle title={'Configuración'}/>
      </PageSection>

      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <Table columns={columns} data={data} pageSize={100} sortArray={[{id: 'salaId', desc: true}]}
                   searchable={true}/>
        }
      </PageSection>
    </PageContainerFull>
  );
};

export default BillConfig;
