import React from 'react';
import useEditModal from "../../hooks/useEditModal";
import VersionEdit from "./VersionEdit";
import PageContainer from "../../components/PageContainer";
import PageSection from "../../components/PageSection";
import PageTitle from "../../components/Layaout/PageTitle";
import {ContainerLoading} from "../SalasList";
import {Loading} from "../../components/forms/Loading";
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import {useNavigate} from "react-router-dom";
import GenericModal2 from "../../hooks/GenericModal2";
import {Table} from "../../components/forms/Table";
import {Preview} from "../../components/forms/Preview";
import {Button} from "@wargostec/react-components";

const VersionList = () => {

  const navigate = useNavigate();

  const {data, loading} = useFetch(Apis.SALAS + '/salas', [])

  const {wrapper: wrapper1, refresh: refresh1, editAction} = useEditModal({
    editComponent: <VersionEdit/>,
  })

  const goBack = () => {
    navigate(-1)
  }

  const columns = [
    {Header: 'Servicio', accessor: 'name'},
    {Header: 'Version actual', accessor: 'version'},
    {
      Header: 'Version asignada', accessor: 'hash',
      Cell: ({cell}) => (
        <>
          <div>
            {cell.row.original.id}
          </div>
        </>
      )
    },
    {
      header: " ", accessor: 'Actualizar',
      Cell: ({cell}) => (
        <>
          {
            cell.row.original.id > 15 ?
              <div style={{cursor: 'pointer'}} onClick={() => editAction(cell.row.original.id)}>
                <Button text={'Actualizar'} size={'xs'} variant={'success'}/>
              </div>
              :
              <div>
                {" "}
              </div>
          }
        </>
      )
    }
  ];
  return (
    <PageContainer>
      <PageSection>
        <Preview text='Regresar' onClick={goBack}/>
        <PageTitle title={'Version de servidores'}/>
      </PageSection>

      <span>
      </span>
      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <Table columns={columns} data={data}/>
        }
      </PageSection>
      <GenericModal2 {...wrapper1}/>

    </PageContainer>
  );
};

export default VersionList;
