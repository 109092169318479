import { RiBookmark3Fill } from 'react-icons/ri'
import Card from '../card'
import styles from './info-card.module.scss'

type InfoCardProps = {
  title?: string
  subtitle?: string
  icon?: React.ReactNode
  backgroundColor?: string
  onClick?: () => void
}

const InfoCard: React.FC<InfoCardProps> = ({ title, subtitle, icon, backgroundColor = 'whitesmoke', onClick }) => {
  return (
    <Card onClick={onClick}>
      <div className={`${styles['info-card-container']}`}>
        <div>
          <span>{title}</span>
          <span>{subtitle}</span>
        </div>
        <div style={{ backgroundColor }}>
          {icon ? icon : <RiBookmark3Fill size={20} />}
        </div>
      </div>
    </Card>
  )
}

export default InfoCard