import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../util/Apis";
import PageCardTitle from "../components/Layaout/CardTitle";
import BannerForm from "./BannerForm";

const BannerNew = ({setAction, setTitle}) => {

  const create = async (params) => {
    params.salaId = parseInt(params.salaId)
    params.alert = parseInt(params.alert)
    await axios.post(Apis.SALAS + '/banner', params).catch((err) => {
    });
  }

  useEffect(() => {
    setTitle("Nuevo Banner");
  }, []);

  return (
    <>
      <PageCardTitle>
        Nuevo fabricante
      </PageCardTitle>
      <BannerForm value={{}} onSubmit={create} setAction={setAction}/>
    </>
  );
};

export default BannerNew;
