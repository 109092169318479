import React from "react";
import styled from "styled-components";

const CardTitleSpan = styled.h6`
  font-size: 1.2rem;
  font-weight: 700;

  margin-bottom: 0.4rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 24px;
  }

  //ECWIND
  @media screen and (min-width: 768px) {
    font-size: 1.5rem;
    line-height: 38px;
  }
`;

// TODO :  Evaluate if icon is needed
const PageCardTitle = ({title, icon}) => {
  return (
    <CardTitleSpan>
      {title}
      {icon}
    </CardTitleSpan>
  );
};


export default PageCardTitle;


