import styles from './page-section.module.scss'

type PageSectionProps = {
  children?: React.ReactNode
  backgroundColor?: string
}

const PageSection: React.FC<PageSectionProps> = ({ children, backgroundColor }) => {
  return (
    <div className={`${styles['page-section-container']}`} style={{ backgroundColor }} >{children}</div>
  )
}

export default PageSection