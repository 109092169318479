// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ah2jxq_G5KsEfn9y_mON{display:flex;width:100%;flex-direction:column;gap:8px;align-items:center;flex:1;border-radius:8px 8px 0 0}`, "",{"version":3,"sources":["webpack://./src/components/sidebar-body/sidebar-body.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAA,CACA,UAAA,CACA,qBAAA,CACA,OAAA,CACA,kBAAA,CACA,MAAA,CACA,yBAAA","sourcesContent":[".sidebar-body {\n\tdisplay: flex;\n\twidth: 100%;\n\tflex-direction: column;\n\tgap: 8px;\n\talign-items: center;\n\tflex: 1;\n\tborder-radius: 8px 8px 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar-body": `Ah2jxq_G5KsEfn9y_mON`
};
export default ___CSS_LOADER_EXPORT___;
