import React from 'react'
import styles from './checkbox.module.scss'

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(({ label, type, id = "wargos_checkbox", ...props }, ref) => {
  return (
    <div className={styles['checkbox-container']}>
      <input type="checkbox" ref={ref} id={id} {...props} />
      <label htmlFor={id}>{label}</label>
    </div>
  )
})

Checkbox.displayName = "Checkbox"

export default Checkbox