import React, {useEffect, useState} from 'react';
import PageSection from "../../components/PageSection";
import PageTitle from "../../components/Layaout/PageTitle";
import {ContainerLoading} from "../SalasList";
import {Loading} from "../../components/forms/Loading";
import {Table} from "../../components/forms/Table";
import GenericModal2 from "../../hooks/GenericModal2";
import useEditModal from "../../hooks/useEditModal";
import TenantEdit from "./TenantEdit";
import TenantNew from "./TenantNew";
import axios from "axios";
import {Apis} from "../../util/Apis";
import useFetch from "../../hooks/useFetch";
import {MdFiberManualRecord} from "react-icons/md";
import {Modalverification} from "../../components/forms/ModalVerification";
import {Button} from "@wargostec/react-components";

const TenantList = () => {

  const {data, loading, fetchData} = useFetch(Apis.USER + '/internal/tenants', [])

  const deleteElement = (id) => {
    axios.delete(Apis.USER + '/internal/tenants/' + id)
      .then(res => {
        fetchData()
      })
      .catch(error => {
      })
  }

  const {wrapper: wrapper1, refresh: refresh1, addAction, editAction} = useEditModal({
    editComponent: <TenantEdit/>,
    addComponent: <TenantNew/>
  })

  useEffect(() => {
    fetchData()
  }, [refresh1])

  const columns = [
    {Header: 'Tenant', accessor: 'name'},
    {
      Header: 'Activado',
      accessor: 'enabled',
      Cell: ({cell}) => {
        return cell.value ? <MdFiberManualRecord color={'#60FC1C'} size={28}/> :
          <MdFiberManualRecord color={'#bdb6b5'} size={28}/>
      },
      sortType: 'basic'
    },
    {
      Header: 'Gaming',
      accessor: 'gaming',
      Cell: ({cell}) => {
        return cell.value ? <MdFiberManualRecord color={'#60FC1C'} size={28}/> :
          <MdFiberManualRecord color={'#bdb6b5'} size={28}/>
      },
      sortType: 'basic'
    },
    {
      Header: 'Asistencia',
      accessor: 'asis',
      Cell: ({cell}) => {
        return cell.value ? <MdFiberManualRecord color={'#60FC1C'} size={28}/> :
          <MdFiberManualRecord color={'#bdb6b5'} size={28}/>
      },
      sortType: 'basic'
    },
    {
      Header: 'Acciones',
      Cell: ({cell}) => {
        const [isDelete, setIsDelete] = useState(false);
        return (
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            <Button text={'Editar'} size={'xs'} variant={'success'} onClick={() => editAction(cell.row.original.name)}/>
            <Button text={'Eliminar'} size={'xs'} variant={'danger'} onClick={() => setIsDelete(true)}/>
            <Modalverification
              show={isDelete}
              handleClose={() => setIsDelete(false)}
              title='¿Seguro que desea eliminar este tenant?'
              handleDelete={() => deleteElement(cell.row.original.name)}
            />
          </div>
        );
      },
    },

  ];

  return (
    <>
      <PageSection>
        <PageTitle title={'Tenants'}/>
        <Button text={'Nuevo'} variant={'primary'} onClick={addAction}/>
      </PageSection>

      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <Table columns={columns} data={data && data.sort((a, b) => a.name.localeCompare(b.name))}/>
        }
      </PageSection>
      <GenericModal2 {...wrapper1}/>
      </>
  );
};

export default TenantList;
