import React, {useEffect, useState} from 'react';
import * as FaIcons from "react-icons/fa";
import {FaEdit, FaPlusCircle, FaTrashAlt} from "react-icons/fa";
import axios from "axios";
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import {errorToast} from "../../components/Layaout/ToastStyles";
import useDeleteModal from '../../hooks/useDeleteModal';
import useEditModal from "../../hooks/useEditModal";
import PageContainer from "../../components/PageContainer";
import PageSection from "../../components/PageSection";
import GenericModal2 from "../../hooks/GenericModal2";
import DeleteGenericModal from "../../hooks/DeleteGenericModal";
import Table from "../../components/styles/Table";
import {useNavigate} from "react-router-dom";
import UserGroupEdit from "./UserGroupEdit";
import UserGroupNew from "./UserGroupNew";
import ActionEdit from "./ActionEdit";
import ActionNew from "./ActionNew";
import ActionGroupNew from "./ActionGroupNew";
import ActionGroupEdit from "./ActionGroupEdit";
import Input, {SelectV2} from "../../components/forms/FormEl";
import {Loading} from "../../components/forms/Loading";
import {FormBlock} from "../../components/FormGroup";
import {SwitchToggle} from "../../components/forms/SwitchToggle";
import {Button} from "@wargostec/react-components";
import PageTitle from "../../components/Layaout/PageTitle";

const PrivSwichButton = ({actionId, groupId, enabled, reload}) => {

  if (enabled === undefined) {
    enabled = false
  }

  const [switchValue, setSwitchValue] = useState(enabled)

  const modifyAction = (value) => {
    setSwitchValue(!switchValue)

    let payload = {}
    payload.actionId = actionId
    payload.userGroupId = groupId
    payload.enable = !enabled

    axios.post(Apis.USER + '/actions/modify', payload)
      .then(res => {
        reload()
      })
      .catch(err => {
        errorToast(err.response.data.message)
        setSwitchValue(!switchValue)
      });
  }

  return <td>
    <SwitchToggle name={"enabled"}
                  id={"switch" + actionId + "_" + groupId}
                  label={""}
                  defaultChecked={switchValue}
                  onClick={() => modifyAction(enabled)}
    />
  </td>
}

const Permissions = () => {

  const [product, setProduct] = useState('gaming')

  const {loading, data: report, fetchData} = useFetch(Apis.USER + `/actions/report?product=${product}`)

  const deleteElement = profileId => axios.delete(Apis.USER + `/user-group/${profileId}`)
  const deleteAction = actionId => axios.delete(Apis.USER + `/actions/${actionId}`)

  const [params, setParams] = useState("")

  let search = params
  const handleSearch = e => {
    search.term = e.target.value
    setParams(search)
  }

  const {wrapper: wrapper1, refresh, addAction: addProfile, editAction} = useEditModal({
    addComponent: <UserGroupNew/>,
    editComponent: <UserGroupEdit/>
  })

  const {wrapper: wrapper3, refresh: refresh3, addAction: addGroup, editAction: editGroup} = useEditModal({
    addComponent: <ActionGroupNew/>,
    editComponent: <ActionGroupEdit/>
  })

  const {
    wrapper: wrapperAction,
    refresh: refreshAction,
    addAction: addPermissionsA,
    editAction: editPermissionsA
  } = useEditModal({
    addComponent: <ActionNew/>,
    editComponent: <ActionEdit/>
  })

  const {wrapper: wrapper2, refresh: refresh2, openDeleteModal} = useDeleteModal({text: '¿Desea eliminar este perfil?'})

  const {
    wrapper: wrapper4,
    refresh: refresh4,
    openDeleteModal: deletePermissionsAction
  } = useDeleteModal({text: '¿Desea eliminar este acción?'})

  useEffect(() => {
    fetchData()
  }, [refresh, refresh2, refresh3, refreshAction, refresh4])

  const navigate = useNavigate();

  return (
    <PageContainer>
      <PageSection>
        <PageTitle title={'Permisos'}/>
        <Button onClick={() => fetchData()} icon={<FaIcons.FaSync size="15px"/>} text={'Actualizar'}/>
      </PageSection>
      <PageSection style={{marginBottom: '10px'}}>
        <Input placeholder='Buscar por Permisos'/>
      </PageSection>
      <PageSection>
        <label>Producto</label>
        <SelectV2 options={[
          {id: 'gaming', name: 'GAMING'},
          {id: 'asis', name: 'ASISTENCIA'},
          {id: 'admin', name: 'ADMIN'}
        ]}
                  onChange={(event) => setProduct(event.target.value)}
        />
      </PageSection>
      <PageSection>
        <FormBlock>
          {!loading && report ?
            <Table>
              <thead>
              <tr>
                <th></th>
                {
                  report && report.groups.map(item =>
                    <th key={item.id}>
                      <FaEdit style={{marginLeft: '10%', cursor: 'pointer'}}
                              onClick={() => editAction({id: item.id, name: item.name})}></FaEdit>
                      <FaTrashAlt style={{marginLeft: '10%', cursor: 'pointer'}}
                                  onClick={() => openDeleteModal(item.id, deleteElement)}></FaTrashAlt>
                    </th>
                  )
                }
              </tr>
              <tr>
                <th></th>
                {
                  report && report.groups.map(item =>
                    <th key={item.id}>{item.name} </th>
                  )
                }
                {
                  report != null ?
                    <th>
                      <Button text={<FaPlusCircle/>} size={'xs'} variant={'light'} onClick={() => addProfile()}/>
                    </th>
                    :
                    <></>
                }
              </tr>
              </thead>
              <tbody>
              {
                report && report.actionGroups.sort((a, b) => a.groupName.localeCompare(b.groupName)).map(group =>
                  <>
                    <tr>
                      <th>
                        <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                          <p style={{margin: '16px 0', fontWeight: 'bold', fontSize: '1.2rem'}}>{group.groupName}</p>
                          <Button text={'Editar'} size={'xs'} variant={'light'}
                                  onClick={() => editGroup(group.actions[0].actionGropuId)}/>
                        </div>
                      </th>
                    </tr>
                    {group.actions.sort((a, b) => a.name.localeCompare(b.name)).map(item =>
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        {/*{report.groups.map(pi => {*/}
                        {/*  return <PrivSwichButton actionId={item.id} groupId={pi.id} enabled={item.data[pi.id]}*/}
                        {/*    reload={fetchData} />*/}
                        {/*}*/}
                        {/*)}*/}
                        <td></td>
                        <td></td>
                        <td>
                          <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                            <Button text={'Editar'} size={'xs'} variant={'light'}
                                    onClick={() => editPermissionsA(item.id)}/>
                            <Button text={`Endpoints | ${item.endpoints}`} size={'xs'} variant={'success'}
                                    onClick={() => navigate('/actions-endpoints/' + item.id)}/>
                            <Button text={'Eliminar'} size={'xs'} variant={'danger'}
                                    onClick={() => deletePermissionsAction(item.id, deleteAction)}/>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                )
              }
              </tbody>
            </Table> :
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30vh'}}>
              <Loading variant='secondary'/>
            </div>
          }
        </FormBlock>
        <div style={{display: 'flex', gap: '1em'}}>
          <Button variant={'primary'} onClick={() => addGroup()}>
            <FaPlusCircle style={{marginRight: 2}}></FaPlusCircle>
            Nuevo Grupo
          </Button>
          <Button variant={'primary'} onClick={() => addPermissionsA()}>
            <FaPlusCircle style={{marginRight: 2}}></FaPlusCircle>
            Nueva Acción
          </Button>
        </div>
      </PageSection>
      <GenericModal2 {...wrapper1} />
      <GenericModal2 {...wrapper3} />
      <GenericModal2 {...wrapperAction} />
      <DeleteGenericModal {...wrapper2} />
      <DeleteGenericModal {...wrapper4} />
    </PageContainer>
  );
};

export default Permissions;
