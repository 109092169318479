import React, { useState } from 'react'
import FlexContainer from '../flex-container'
import TabContent from '../tab-content'
import { type TabProps } from '../tab'

type TabsProps = {
  children?: React.ReactNode
}

const Tabs: React.FC<TabsProps> = ({ children }) => {
  const [active, setActive] = useState(0)

  return (
    <div role="tablist">
      <FlexContainer flexDirection='row' flexWrap='wrap' gap={8} margin={'0 0 16px 0'}>
        {React.Children.map(children, (child: React.ReactElement<TabProps>, index) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { ...child.props, isActive: index === active, onClick: () => setActive(index), key: index });
          }
        })}
      </FlexContainer>
      <FlexContainer flexDirection='row' flexWrap='wrap' margin={'0 0 16px 0'}>
        {React.Children.map(children, (child: React.ReactElement<TabProps>, index) => {
          if (React.isValidElement(child)) {
            return (
              <TabContent key={index} isActive={index === active}>
                {child.props.children}
              </TabContent>
            )
          }
        })}
      </FlexContainer>
    </div>
  )
}

export default Tabs