// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rbVJLI_hGl7BG797JHA9>.JLgIuq14k70EIYNRJRUT{position:fixed;z-index:999;top:0;left:0;width:100vw;height:100vh;background-color:rgba(0,0,0,.5);display:flex;align-items:center;justify-content:center;visibility:hidden;opacity:0;transition:opacity ease .4s}.rbVJLI_hGl7BG797JHA9>.JLgIuq14k70EIYNRJRUT.bFm2xeXzxKa85IhYij8a{visibility:visible;opacity:1}.rbVJLI_hGl7BG797JHA9>.JLgIuq14k70EIYNRJRUT>img{width:auto;height:auto;max-width:100%;max-height:100%;object-fit:contain}`, "",{"version":3,"sources":["webpack://./src/components/viewer-image/viewer-image.module.scss"],"names":[],"mappings":"AACE,4CACE,cAAA,CACA,WAAA,CACA,KAAA,CACA,MAAA,CACA,WAAA,CACA,YAAA,CAEA,+BAAA,CAEA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,iBAAA,CACA,SAAA,CAEA,2BAAA,CAEA,iEACE,kBAAA,CACA,SAAA,CAGF,gDACE,UAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":[".viewer-image-container {\n  &>.lightbox {\n    position: fixed;\n    z-index: 999;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n\n    background-color: rgba(0, 0, 0, 0.5);\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    visibility: hidden;\n    opacity: 0;\n\n    transition: opacity ease 0.4s;\n\n    &.show {\n      visibility: visible;\n      opacity: 1;\n    }\n\n    &>img {\n      width: auto;\n      height: auto;\n      max-width: 100%;\n      max-height: 100%;\n      object-fit: contain;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewer-image-container": `rbVJLI_hGl7BG797JHA9`,
	"lightbox": `JLgIuq14k70EIYNRJRUT`,
	"show": `bFm2xeXzxKa85IhYij8a`
};
export default ___CSS_LOADER_EXPORT___;
