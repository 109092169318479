import React, {useEffect} from "react";
import axios from "axios"
import {Apis} from "../../util/Apis";
import ActionGroupForm from "./ActionGroupForm";

const ActionGroupNew = ({setAction, setTitle}) => {

  const save = (params) => {
    return axios.post(Apis.USER + '/actions-group', params);
  }

  useEffect(() => {
    setTitle("Nuevo Grupo")
  }, [])

  return (
    <>
      <ActionGroupForm value={{}} onSubmit={save} setAction={setAction}/>
    </>
  )
}

export default ActionGroupNew
