import React, {useEffect} from 'react';
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import axios from "axios";
import PermissionsFormAction from "./PermissionsFormAction";

const ActionEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch(Apis.USER + `/actions/${id}`, []);

  function update(params) {
    return axios.put(Apis.USER + '/actions/' + id, params);
  }

  useEffect(() => {
    setTitle('Editar Acción');
  }, []);

  return (
    <div>
      {!loading && data &&
        <PermissionsFormAction value={data} onSubmit={update} setAction={setAction}/>
      }
    </div>
  );
};

export default ActionEdit;
