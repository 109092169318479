import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PageContainer from "../../components/PageContainer";
import PageSection from "../../components/PageSection";
import PageTitle from "../../components/Layaout/PageTitle";
import {Button, Table} from "@wargostec/react-components";
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import {DateTime} from "luxon";
import useEditModal from "../../hooks/useEditModal";
import ProgrammingEdit from "./ProgrammingEdit";
import GenericModal2 from "../../hooks/GenericModal2";
import axios from "axios";
import {Modalverification} from "../../components/forms/ModalVerification";
import styled from "styled-components";

export const ButtonBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
`;

const ProgrammingList = () => {

  const {loading, data, fetchData} = useFetch(Apis.TGM + '/internal/pangolin-zero', null);

  const {wrapper: wrapper1, refresh: refresh1, addAction, editAction} = useEditModal({
    editComponent: <ProgrammingEdit/>
  })

  const dataRef = useRef(data);
  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  const updatedValue = useCallback(props => <>
      {
        <div>
          {props.renderValue() ?
            <span>{DateTime.fromISO(props.renderValue()).toFormat("yyyy'-'LL'-'dd' 'HH':'mm' ")}</span> :
            <span>---</span>}
        </div>
      }
    </>
    , []);

  const deleteElement = (id) => {
    axios.delete(Apis.TGM + '/internal/pangolin-zero/' + id)
      .then(res => {
        fetchData()
      })
      .catch(error => {
      })
  }

  useEffect(() => {
    fetchData()
  }, [refresh1])

  useEffect(() => {
    let intervalId = setInterval(fetchData, 800);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const [previousData, setPreviousData] = useState(null);

  const playBeep = () => {
    const beep = new Audio('/single-beep.wav'); // Ruta al archivo de sonido
    beep.play();

    // Lógica adicional de la función
    console.log('Función ejecutada y sonido reproducido');
  };

  useEffect(() => {
    //console.log('previousData', previousData);
    //console.log('data', data);
    if (previousData !== null && data!== null) {
      if (previousData.length < data.length) {
        //APARECIO UN NUEVO ELEMENT

        playBeep();

        //console.log('Se agrego un nuevo elemento');
        const newElement = data.filter((item) => !previousData.some((item2) => item.id === item2.id));
        //console.log(newElement);
        editAction(newElement[0].id);
      }
    }
    setPreviousData(data);
  }, [data]);

  const actionValue = useCallback((props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isDelete, setIsDelete] = useState(false);
    const id = props.row.original.id;

    return (
      <>
        <ButtonBlock>
          <Button text={'Asignar'} size={'lg'} variant={'primary'} onClick={() => editAction(id)}/>
          <Button text={'Eliminar'} size={'lg'} variant={'danger'} onClick={() => setIsDelete(true)}/>
          <Modalverification
            show={isDelete}
            handleClose={() => setIsDelete(false)}
            title='¿Seguro que desea eliminar esta configuracion?'
            handleDelete={() => deleteElement(id)}
          />
        </ButtonBlock>
      </>
    );
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === 'k' || event.key === 'K') {
      console.log('teclada presionada');
      //console.log(dataRef);
      if (dataRef && dataRef.current.length > 0) {
        //console.log('hay data')
        const firstEntry = dataRef.current[0];
        editAction(firstEntry.id);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    // Limpieza del event listener al desmontar el componente
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const columnsZero = useMemo(
    () => [
      {header: 'Id', accessorKey: 'id'},
      {header: 'Mac', accessorKey: 'mac'},
      {header: 'IP Publica', accessorKey: 'publicIp'},
      {header: 'SN asignado', accessorKey: 'assignedSn'},
      {header: 'SN', accessorKey: 'sn'},
      {
        header: 'Fecha de actualización',
        accessorKey: 'updatedAt',
        cell: updatedValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Acciones',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionValue
      },
    ]
    , []
  )

  return (
    <PageContainer>
      <PageSection>
        <PageTitle title={'Asignacion serial'}/>
      </PageSection>

      <>
        {
          (data !== null) &&
          <PageSection>

            <Table columns={columnsZero} data={data}/>
            {
              loading && <h5>Actualizando...</h5>
            }
          </PageSection>
        }
      </>

      <GenericModal2 {...wrapper1}/>
    </PageContainer>
  );
};

export default ProgrammingList;
