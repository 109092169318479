import styles from './tooltip.module.scss'

type TooltipProps = {
  text?: string
  children?: React.ReactNode
  direction?: 'top' | 'bottom' | 'left' | 'right'; 
}

const Tooltip: React.FC<TooltipProps> = ({children, text, direction = 'right'}) => {

  const containerClassName = `${styles['tooltip-container']} ${styles[direction]}`;

  return (
    <div className={containerClassName}>
      {children}
      <span>{text}</span>
    </div>
  )
}

export default Tooltip