import React from "react";


const SwitchToggle = React.forwardRef((props, ref) => {
  return (
    <div className="form-check form-switch">
      <input type="checkbox" className="form-check-input" ref={ref} {...props}/>
      <label className="form-check-label"
             htmlFor={props.id}>
        {props.label}
      </label>
    </div>
  )
})

export default SwitchToggle
