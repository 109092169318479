import React, {useEffect} from 'react';
import useFetch from "../../../hooks/useFetch";
import {Apis} from "../../../util/Apis";
import axios from "axios";
import PangolinLoteForm from "./PangolinLoteForm";

const PangolinLoteEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch(Apis.TGM + '/pangolin-lote/' + id);

  function update(params) {
    params.id = id
    return axios.put(Apis.TGM + '/pangolin-lote/' + id, params);
  }

  useEffect(() => {
    setTitle('Editar lote');
  }, []);

  return (
    <div>
      {!loading && data &&
        <PangolinLoteForm value={data} onSubmit={update} setAction={setAction}/>
      }
    </div>
  );
};

export default PangolinLoteEdit;
