import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../util/Apis";
import PageCardTitle from "../../../components/Layaout/CardTitle";
import ServerForm from "./ServerForm";

const ServerNew = ({setAction, setTitle}) => {

  const create = async (params) => {
    await axios.post(Apis.TGM + '/pangolin-server', params).catch((err) => {
    });
  }

  useEffect(() => {
    setTitle("Nuevo servidor");
  }, []);

  return (
    <>
      <PageCardTitle>
        Nuevo Servidor
      </PageCardTitle>
      <ServerForm value={{}} onSubmit={create} setAction={setAction}/>

    </>
  );
};

export default ServerNew;
