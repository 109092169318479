import React, {useEffect, useState} from 'react';
import PageSection from "../../components/PageSection";
import {useForm} from "react-hook-form";
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import PageContainer from "../../components/PageContainer";
import axios from "axios";
import {errorToast, successToast} from "../../components/Layaout/ToastStyles";
import {GiCancel} from "react-icons/gi";
import PageTitle from "../../components/Layaout/PageTitle";
import SalasForm from "./SalasForm";
import {useParams} from "react-router";
import {Preview} from "../../components/forms/Preview";
import {Button} from "@wargostec/react-components";
import {useNavigate} from "react-router-dom";

export const decimalToInteger = (value) => {
  if (value % 1 === 0) {
    return parseInt(value * 100)
  }
  const decimals = value.toString().split(".")[1].length
  if (decimals === 1) {
    value = value * 10
  }
  value = value * Math.pow(10, decimals)
  return parseInt(value)
}

const EditSalas = () => {

  const {id} = useParams()
  const navigate = useNavigate();

  const {data} = useFetch(Apis.SALAS + '/salas/' + id, []);
  const {reset, getValues, setValue} = useForm();
  const [pcs, setPcs] = useState([])

  const goBack = () => {
    navigate(-1)
  }

  const update = async (params) => {
    params.id = id
    params.salaId = parseInt(params.salaId)
    params.companyId = parseInt(params.companyId)
    params.registroMincetur = parseInt(params.registroMincetur)
    params.ruc = parseInt(params.ruc)
    params.pcs = pcs
    params.config = {
      onlineSystem: params.config.onlineSystem,
      onlinePassword: params.config.onlinePassword,
      onlineUrl: params.config.onlineUrl,
      onlineUrlPrivate: params.config.onlineUrlPrivate,
      onlineUsername: params.config.onlineUsername,
      linktekPages: parseInt(params.config.linktekPages),
      periodScan: parseInt(params.config.periodScan),
      periodScanSessions: parseInt(params.config.periodScanSessions),
      scanMeter: params.config.scanMeter,
      scanCard: params.config.scanCard
    }
    params.billing = {
      ruc: parseInt(params.billing.ruc),
      machinesQuantity: parseInt(params.billing.machinesQuantity),
      fee: decimalToInteger(params.billing.fee),
      currency: params.billing.currency,
      invoiceDescription: params.billing.invoiceDescription,
      active: params.billing.active,
      plan: params.billing.plan,
      paymentCycle: params.billing.paymentCycle,
      email: params.billing.email
    }

    params.currency = {
      currencyIso: params.currency.currencyIso,
      currencySymbol: params.currency.currencySymbol
    }

    await axios.put(Apis.SALAS + '/salas/' + id, params)
      .then(res => {
        successToast()
        goBack()
      }).catch(err => {
        errorToast()
      })
  }

  const columns = [
    {Header: 'Id', accessor: 'id'},
    {Header: 'Nombre', accessor: 'name'},
    {Header: 'Modo', accessor: 'mode'},
    {
      Header: 'Acciones',
      Cell: ({cell}) => {
        return (
          <div>
            <Button variant={'light'} size={'xs'}
                    onClick={() => removePcs(cell.row.original.id)}><GiCancel
              size={'20px'}/></Button>
          </div>
        );
      }
    },
  ]

  const arrayPcs = () => {
    let pcsNew = {
      id: parseInt(getValues('idPCS')),
      name: getValues('namePCS'),
      mode: getValues('modePCS')
    }
    setPcs([...pcs, pcsNew])
    setValue('idPCS', "")
    setValue('namePCS', "")
    setValue('modePCS', "")
    console.log(pcs)
  }

  const removePcs = (element) => {
    const arrayList = [...pcs]
    let indice = pcs.findIndex((item, indice) => {
      if (item.id === element) {
        return true;
      }
    });
    console.log(arrayList)
    arrayList.splice(indice, 1)
    return setPcs(arrayList)
  }

  useEffect(() => {
    if (data) {
      reset(data)
      setPcs(data.pcs)
    }
  }, [data])

  return (
    <PageContainer>
      <PageSection>
        <Preview text='Regresar' onClick={goBack}/>
        <PageTitle title={'Editar sala'}/>

        <div>
          <SalasForm value={data && data} onSubmit={update}/>
        </div>
      </PageSection>
    </PageContainer>
  );
};

export default EditSalas;
