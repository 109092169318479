// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zOjQjvBLe9BLfBsPnfCc{display:flex;flex-direction:row;gap:4px;align-items:center;padding:8px;border-radius:8px;cursor:pointer;border:none;background-color:rgba(0,0,0,0)}.zOjQjvBLe9BLfBsPnfCc:hover,.zOjQjvBLe9BLfBsPnfCc.P_oXPuC5zKmAl3FRiAYX{background-color:#404040}.zOjQjvBLe9BLfBsPnfCc>svg{color:#fff}`, "",{"version":3,"sources":["webpack://./src/components/sidebar-item/sidebar-item.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAA,CACA,kBAAA,CACA,OAAA,CACA,kBAAA,CACA,WAAA,CACA,iBAAA,CACA,cAAA,CAEA,WAAA,CACA,8BAAA,CAEA,uEAEC,wBAAA,CAGD,0BACC,UAAA","sourcesContent":[".sidebar-item {\n\tdisplay: flex;\n\tflex-direction: row;\n\tgap: 4px;\n\talign-items: center;\n\tpadding: 8px;\n\tborder-radius: 8px;\n\tcursor: pointer;\n\n\tborder: none;\n\tbackground-color: transparent;\n\n\t&:hover,\n\t&.active {\n\t\tbackground-color: #404040;\n\t}\n\n\t& > svg {\n\t\tcolor: white;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar-item": `zOjQjvBLe9BLfBsPnfCc`,
	"active": `P_oXPuC5zKmAl3FRiAYX`
};
export default ___CSS_LOADER_EXPORT___;
