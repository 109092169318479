// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.UWh4tyjg9E0MGMvs7EZU{display:flex;align-items:center}div.UWh4tyjg9E0MGMvs7EZU>div:first-child{display:flex;flex-direction:column;flex:1}div.UWh4tyjg9E0MGMvs7EZU>div:first-child>span{font-family:"Noto Sans",sans-serif;line-height:150%}div.UWh4tyjg9E0MGMvs7EZU>div:first-child>span:first-child{color:#67748e;font-weight:600;font-size:14px}div.UWh4tyjg9E0MGMvs7EZU>div:first-child>span:last-child{color:#212529;font-weight:700;font-size:24px}div.UWh4tyjg9E0MGMvs7EZU>div:last-child{color:#212529;padding:12px;border-radius:8px;width:44px;height:44px;box-sizing:border-box}`, "",{"version":3,"sources":["webpack://./src/components/info-card/info-card.module.scss"],"names":[],"mappings":"AAAA,yBACC,YAAA,CACA,kBAAA,CAEA,yCACC,YAAA,CACA,qBAAA,CACA,MAAA,CAEA,8CACC,kCAAA,CACA,gBAAA,CAGD,0DACC,aAAA,CACA,eAAA,CACA,cAAA,CAGD,yDACC,aAAA,CACA,eAAA,CACA,cAAA,CAIF,wCACC,aAAA,CACA,YAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,qBAAA","sourcesContent":["div.info-card-container {\n\tdisplay: flex;\n\talign-items: center;\n\n\t& > div:first-child {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tflex: 1;\n\n\t\t& > span {\n\t\t\tfont-family: 'Noto Sans', sans-serif;\n\t\t\tline-height: 150%;\n\t\t}\n\n\t\t& > span:first-child {\n\t\t\tcolor: #67748e;\n\t\t\tfont-weight: 600;\n\t\t\tfont-size: 14px;\n\t\t}\n\n\t\t& > span:last-child {\n\t\t\tcolor: #212529;\n\t\t\tfont-weight: 700;\n\t\t\tfont-size: 24px;\n\t\t}\n\t}\n\n\t& > div:last-child {\n\t\tcolor: #212529;\n\t\tpadding: 12px;\n\t\tborder-radius: 8px;\n\t\twidth: 44px;\n\t\theight: 44px;\n\t\tbox-sizing: border-box;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-card-container": `UWh4tyjg9E0MGMvs7EZU`
};
export default ___CSS_LOADER_EXPORT___;
