import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import FormGroup from "../../../components/FormGroup";
import SelectSalas from "../../../Selects/SelectSalas";
import {Input} from "@wargostec/react-components";

const InvoiceForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, watch, reset, control} = useForm();

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  return (
    <>
      <React.Fragment>
        <FormGroup>
          <label>Sala</label>
          <Controller
            name="salaId"
            control={control}
            render={({field}) => <SelectSalas {...field} />}
          />
        </FormGroup>
        <FormGroup>
          <label>Fecha</label>
          <Input type="month" {...register("date")} />
        </FormGroup>
      </React.Fragment>

    </>
  );
};

export default InvoiceForm;
