import React from "react";
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import {SelectV2} from "../components/forms/FormEl";

const SelectServer = React.forwardRef((props, ref) => {
  const salaId = props.salaId;
  const {data, loading, error} = useFetch(Apis.TGM + '/pangolin-server', [])

  const getData = (dataX) => {
    let convertedData = []
    if (dataX) {
      if (salaId !== null || salaId !== "") {
        dataX = dataX.filter(item => item.salaId === salaId)
      }
      convertedData = dataX.map(item => {
        return {
          id: item.id,
          name: item.name
        }
      })
    }
    return convertedData
  }

  return (
    <>
      {
        !loading &&
        <SelectV2 ref={ref} options={getData(data)} {...props}/>
      }
    </>
  )
})

export default SelectServer;
