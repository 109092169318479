import React from 'react'
import styles from './switch.module.scss'

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string
}

const Switch = React.forwardRef<HTMLInputElement, CheckboxProps>(({ label, type, id = "wargos_switch", ...props }, ref) => {
  return (
    <div className={styles["container"]}>
      <label className={styles["toggle-switch"]}>
        <input type="checkbox" ref={ref} id={id} {...props} />
        <span className={styles["switch"]} />
      </label>
      <label htmlFor={id}>{label}</label>
    </div>
  )
})

Switch.displayName = "Switch"

export default Switch