import React, {useEffect} from 'react';
import PageCardTitle from "../../../components/Layaout/CardTitle";
import axios from "axios";
import {Apis} from "../../../util/Apis";
import CommentForm from "./CommentForm";

const CommentNew = ({id, setAction, setTitle}) => {

  const create = async (params) => {
    params.sn = id
    await axios.post(Apis.TGM + '/pangolin-comment', params).catch((err) => {
    });
  }

  useEffect(() => {
    setTitle("Nuevo comentario | SN : " + id);
  }, []);

  return (
    <>
      <PageCardTitle>
        Nuevo comentario
      </PageCardTitle>
      <CommentForm value={{}} onSubmit={create} setAction={setAction}/>
    </>
  );
};

export default CommentNew;
