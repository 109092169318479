import { createGlobalStyle } from "styled-components";

export const CreateStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-size: 14px;
  }

  body {
    background: #F8F9FA;
    color: #1E1E1E;
    font-weight: 400;
  }

  li{
    text-decoration: none;
  }

  a{
    text-decoration: none;
  }

  .myActiveLink span{
    background:#404040;
    border-radius:6px;
  }
`;
