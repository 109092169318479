type FlexContainerProps = {
  children?: React.ReactNode
  className?: string
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
  alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline'
  padding?: string | number
  margin?: string | number
  gap?: string | number
  flex?: string | number
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse',
  style?: React.CSSProperties,
  onClick?: () => void
}

const FlexContainer: React.FC<FlexContainerProps> = ({ children, flexWrap, className, flexDirection, justifyContent, alignItems, padding, margin, gap, flex, style, onClick }) => {
  return (
    <div className={className} onClick={onClick} style={{ display: 'flex', flexWrap, flexDirection, justifyContent, alignItems, padding, margin, gap, flex, ...style }}>{children}</div>
  )
}

export default FlexContainer