// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.wjfUzeW0rL1lxfP9a7Gw{display:flex;transition:.5s all ease-in-out;flex-wrap:wrap}div.wjfUzeW0rL1lxfP9a7Gw>div.cjoXp30Hop6JzWAzDBJ_{display:flex;flex-wrap:wrap;gap:8px;justify-content:center}div.wjfUzeW0rL1lxfP9a7Gw>div.cjoXp30Hop6JzWAzDBJ_>div.usHNj3F5ouOMrwdKtr3b{height:auto;width:2px;background-color:#fff}@media screen and (max-width: 900px){div.wjfUzeW0rL1lxfP9a7Gw{flex-direction:column}}`, "",{"version":3,"sources":["webpack://./src/components/date-filter/date-filter.module.scss"],"names":[],"mappings":"AAAA,yBACE,YAAA,CACA,8BAAA,CACA,cAAA,CAEA,kDACE,YAAA,CACA,cAAA,CACA,OAAA,CACA,sBAAA,CAEA,2EACE,WAAA,CACA,SAAA,CACA,qBAAA,CAIJ,qCAlBF,yBAmBI,qBAAA,CAAA","sourcesContent":["div.date-filter-container {\n  display: flex;\n  transition: 0.5s all ease-in-out;\n  flex-wrap: wrap;\n\n  & > div.options { \n    display: flex;\n    flex-wrap: wrap;\n    gap: 8px;\n    justify-content: center;\n\n    & > div.divider {\n      height: auto;\n      width: 2px;\n      background-color: white;\n    }\n  }\n\n  @media screen and (max-width: 900px) {\n    flex-direction: column;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date-filter-container": `wjfUzeW0rL1lxfP9a7Gw`,
	"options": `cjoXp30Hop6JzWAzDBJ_`,
	"divider": `usHNj3F5ouOMrwdKtr3b`
};
export default ___CSS_LOADER_EXPORT___;
