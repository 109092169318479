import {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import {IoMdCloseCircleOutline} from "react-icons/io";
import styles from './file-uploader.module.scss'
import {RiFileExcel2Line} from "react-icons/ri";
import {FaRegFilePdf} from "react-icons/fa";
import {LiaCloudUploadAltSolid} from "react-icons/lia";

type FileUploaderProps = {
  value: string;
  domain?: string;
  height?: string | number;
  width?: string | number;
  onUpload: (file: File) => void;
  onDelete?: () => void;
};

const FileUploader: React.FC<FileUploaderProps> = ({value, domain = 'https://wgt-tmp-uploads.s3.amazonaws.com/', height = 160, width = 180, onDelete, onUpload}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: useCallback((acceptedFiles) => {
      acceptedFiles.map(file => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        onUpload(file)
        return file;
      });
    }, []),
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
      'application/pdf': [],
      'application/xlsx': []
    },
    maxFiles: 1,
  })

  const getPreview = () => {
    const parts = value.split('.')
    if (parts[1] === 'xlsx') {
      return <RiFileExcel2Line size={60} color="#14532d" />
    }
    if (parts[1] === 'pdf') {
      return <FaRegFilePdf size={60} color="#b91c1c" />
    } else {
      return <img src={`${domain}${value}`}
        style={{ width: "140px", height: "140px", minWidth: "140px" }}
        alt='preview' />
    }
  }


  return (
    <div className={styles['file-uploader-container']} style={{height, width}}>
      {!value || value.trim() === '' ?
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <LiaCloudUploadAltSolid size={42} />
          <span >
            Arrastre y suelte sus archivos o haga clic aquí para cargarlos
          </span>
        </div>
        :
        <div>
          {getPreview()}
        </div>
      }
      {!value || value.trim() !== '' &&
        <IoMdCloseCircleOutline
          size={28} 
          className={styles['file-uploader-close']}
          onClick={onDelete}
        />
      }
    </div>
  )
}

export default FileUploader