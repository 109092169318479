// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.wtS5ZamtzTpUEUt4d14E{box-sizing:border-box;padding:16px;border-radius:8px;background-color:#fff;box-shadow:0 0 25px 0 rgba(0,0,0,.05);transition:all .2s linear;overflow:hidden}div.wtS5ZamtzTpUEUt4d14E.SOPn1tLhJ3JCYabcx5WL{padding:0}@media screen and (min-width: 768px){div.wtS5ZamtzTpUEUt4d14E{padding:20px 24px}}`, "",{"version":3,"sources":["webpack://./src/components/card/card.module.scss"],"names":[],"mappings":"AAAA,yBACC,qBAAA,CACA,YAAA,CACA,iBAAA,CACA,qBAAA,CACA,qCAAA,CACA,yBAAA,CACA,eAAA,CAEA,8CACC,SAAA,CAIF,qCACC,yBACC,iBAAA,CAAA","sourcesContent":["div.card-container {\n\tbox-sizing: border-box;\n\tpadding: 16px;\n\tborder-radius: 8px;\n\tbackground-color: #fff;\n\tbox-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);\n\ttransition: all 0.2s linear;\n\toverflow: hidden;\n\n\t&.no-padding {\n\t\tpadding: 0;\n\t}\n}\n\n@media screen and (min-width: 768px) {\n\tdiv.card-container {\n\t\tpadding: 20px 24px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card-container": `wtS5ZamtzTpUEUt4d14E`,
	"no-padding": `SOPn1tLhJ3JCYabcx5WL`
};
export default ___CSS_LOADER_EXPORT___;
