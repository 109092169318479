import styles from './menu.module.scss'

export type MenuProps = {
  title?: string
  selected?: string
  icon?: React.ReactNode
  children?: React.ReactNode
  setSelected?: React.Dispatch<React.SetStateAction<string>>
  onClick?: () => void
}

const Menu: React.FC<MenuProps> = ({title, selected, icon, children, setSelected, onClick = () => {}}) => {

  const handleClick = () => {
    if (title === selected) setSelected && setSelected('')
    else setSelected && setSelected(title)

    onClick()
  }

  return (
    <div className={`${styles['menu-container']}`}>
      <div onClick={handleClick}>
        {icon}
        <span>{title}</span>
      </div>
      <ul className={`${title === selected ? styles['unfolded'] : ''}`}>
        {children}
      </ul>
    </div>
  )
}

export default Menu