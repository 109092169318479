import { useToggle } from '../../hooks';
import styles from './viewer-image.module.scss'

type ViewerImageProps = {
  zoom?: boolean
  src?: string
  alt?: string
  width?: string | number
  height?: string | number
  border?: 'square' | 'circle'
}

const borderRadius = {
  circle: '50%',
  square: '0%'
}

const ViewerImage: React.FC<ViewerImageProps> = ({
  zoom = false,
  src = '',
  alt = '',
  height = '140px',
  width = '140px',
  border = 'square'
}) => {
  const { isOpen, toggler } = useToggle();

  if (!zoom) return <img src={src} alt={alt} style={{ height, width, borderRadius: borderRadius[border] }} />

  return (

    <div className={styles['viewer-image-container']}>
      <img src={src} alt={alt} onClick={toggler} style={{ height, width, borderRadius: borderRadius[border] }} />
      {isOpen && (
        <div onClick={toggler} className={`${styles['lightbox']} ${styles['show']}`}>
          <img src={src} />
        </div>
      )}
    </div>

  )
}

export default ViewerImage
