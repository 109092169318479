import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../util/Apis";
import PageCardTitle from "../../components/Layaout/CardTitle";
import TenantForm from "./TenantForm";

const TenantNew = ({setAction, setTitle}) => {

  const create = async (params) => {
    await axios.post(Apis.USER + '/internal/tenants', params).catch((err) => {
    });
  }

  useEffect(() => {
    setTitle("Nuevo tenant");
  }, []);

  return (
    <>
      <PageCardTitle>
        Nuevo fabricante
      </PageCardTitle>
      <TenantForm value={{}} onSubmit={create} setAction={setAction} mode={false}/>
    </>
  );
};

export default TenantNew;
