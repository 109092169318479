// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JezuXvZ8Z6_kygb8NBR3{display:flex;flex-direction:column;align-items:center;gap:8px;border-top:1px solid #262626;padding:16px 0}`, "",{"version":3,"sources":["webpack://./src/components/sidebar-footer/sidebar-footer.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,OAAA,CACA,4BAAA,CACA,cAAA","sourcesContent":[".sidebar-footer {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tgap: 8px;\n\tborder-top: 1px solid #262626;\n\tpadding: 16px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar-footer": `JezuXvZ8Z6_kygb8NBR3`
};
export default ___CSS_LOADER_EXPORT___;
