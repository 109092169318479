import React from 'react';
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import {SelectV2} from "../components/forms/FormEl";

const SelectConnector = React.forwardRef((props, ref) => {

  const {
    loading,
    data,
  } = useFetch(Apis.TGM + '/connectors', [], {});

  return (
    <>
      {
        !loading ?
          <SelectV2 ref={ref} options={data} {...props}/>
          :
          <SelectV2 disabled options={[{id: 0, name: 'Seleccione...'}]}/>
      }
    </>
  );
});

export default SelectConnector;
