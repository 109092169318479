import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import SelectSalas from "../../Selects/SelectSalas";
import FormGroup from "../../components/FormGroup";
import axios from "axios";
import {Apis} from "../../util/Apis";
import {Input} from "@wargostec/react-components";

const RunRestart = ({setAction, setTitle}) => {

  const {handleSubmit, control, register} = useForm();

  const restart = (params) => {
    if (params.confirm !== 'reiniciar todo') {
      const error = {
        response: {
          data: {
            message: 'Frase de confirmación incorrecta',
          },
        },
      };
      return Promise.reject(error);
    }
    return axios.post(Apis.TGM + `/internal/restart-all?salaId=${params.salaId}`)
  }

  useEffect(() => {
    setTitle("Reinicio global")
  }, []);

  useEffect(() => {
    setAction(() => handleSubmit(restart))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <FormGroup>
        <label>Sala Id</label>
        <Controller
          name="salaId"
          control={control}
          render={({field}) => <SelectSalas {...field} />}
        />
      </FormGroup>
      <FormGroup>
        <label>Ingrese la frase "reiniciar todo"</label>
        <Input type="text" {...register('confirm')}/>
      </FormGroup>
    </>
  );
};

export default RunRestart;
