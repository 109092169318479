import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import PageSection from "../../components/PageSection";
import FormGroup from "../../components/FormGroup";
import Input from "../../components/forms/FormEl";

const GameForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset, control} = useForm();

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      reset(value)
    }
  }, [value])

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  return (
    <div>
      <PageSection>
        <FormGroup>
          <label style={{fontWeight: 'bold'}}>Game</label>
          <Input type={'text'} {...register('name')}/>
        </FormGroup>
      </PageSection>
    </div>
  );
};

export default GameForm;
