import styles from './sidebar-body.module.scss';

type SidebarBodyProps = {
  children?: React.ReactNode
}

const SidebarBody: React.FC<SidebarBodyProps> = ({children}) => {
  return (
    <div className={`${styles['sidebar-body']}`}>
      {children}
    </div>
  )
}

export default SidebarBody