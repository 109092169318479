import React, {useEffect} from "react";
import {Apis} from "../../util/Apis";
import axios from "axios"
import {useForm} from "react-hook-form";
import FormGroup from "../../components/FormGroup";
import Input from "../../components/forms/FormEl";

const UserGroupEdit = ({ id, setAction, setTitle }) => {
  const { register, handleSubmit } = useForm({ defaultValues: { name: id.name } });

  const save = (params) => {
    return axios.put(Apis.USER + `/user-group/${id.id}`, params);
  }

  useEffect(() => {
    setTitle("Editar Perfil")
    setAction(() => handleSubmit(save))
  }, [])

  return (
    <FormGroup>
      <label>Ingrese el nuevo nombre del perfil</label>
      <Input type={'text'} {...register("name")} />
    </FormGroup>
  )
}

export default UserGroupEdit
