import React, {useEffect} from 'react';
import PageSection from "../components/PageSection";
import {Col, Row} from "react-bootstrap";
import FormGroup from "../components/FormGroup";
import {Controller, useForm} from "react-hook-form";
import SwitchToggle from "../components/Layaout/SwitchToggle";
import SelectSalas from "../Selects/SelectSalas";
import {AiOutlineBgColors} from "react-icons/ai";
import Input, {SelectV2} from "../components/forms/FormEl";

const BannerForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, reset,control} = useForm();

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  return (
    <div>
      <PageSection>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Sala</label>
              <Controller
                name="salaId"
                control={control}
                render={({field}) => <SelectSalas {...field} />}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Titulo</label>
              <Input type={'text'} {...register('title')}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Mensaje</label>
              <Input type={'text'} {...register('message')}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label>Habilitado</label><br/>
              <SwitchToggle
                label={'Si'}
                {...register("enabled")}/>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label>Cerrado activado</label><br/>
              <SwitchToggle
                label={'Si'}
                {...register("fixed")}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Alerta</label>
              <Input type={'number'} {...register('alert')}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Comentario</label>
              <Input type={'text'} {...register('comment')}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Color</label>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <span style={{flex: 1, alignSelf: "center"}}>Color de fondo</span>
                <div style={{flex: 1, textAlign: "right"}}>
                  <AiOutlineBgColors fontSize={'22px'}/>
                  <input type='color' style={{padding: 0, width: 100,verticalAlign: "top"}} {...register("hexColor")}/>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Color</label>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <span style={{flex: 1, alignSelf: "center"}}>Color de texto</span>
                <div style={{flex: 1, textAlign: "right"}}>
                  <AiOutlineBgColors fontSize={'22px'}/>
                  <input type='color' style={{padding: 0, width: 100,verticalAlign: "top"}} {...register("textColor")}/>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Tipo</label>
              <SelectV2 options={
                [
                  {id: 'popup', name: 'POPUP'},
                  {id: 'topbar', name: 'TOPBAR'},
                ]
              }  {...register('type')}/>
            </FormGroup>
          </Col>
        </Row>
      </PageSection>
    </div>
  );
};

export default BannerForm;
