import React from 'react';
import {useDropzone} from "react-dropzone";
import {FaPlus} from "react-icons/fa";

const DropZone = ({onDrop}) => {

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
      'application/*': ['.pdf', '.xlsx', '.xlsm'],
    },
  })
  return (
    <div className="dropzone-div" {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center">
        {isDragActive ? (
          <p className="dropzone-content">Suelte para subir archivo</p>
        ) : (
          <div>
            <FaPlus fontSize={'50'} color={'#4d4e4f'}/>
            <p style={{userSelect : 'none'}}>
              Arrastre y suelte o haga clic para subir un archivo
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropZone;
