// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.lwtL9dMde9KYxo_UPKb8{cursor:pointer;font-weight:normal;color:#637381;padding:.6rem 0;display:flex;align-items:center;width:fit-content;font-family:"Noto Sans",sans-serif}span.lwtL9dMde9KYxo_UPKb8:hover{color:#212b36;text-decoration:none}span.lwtL9dMde9KYxo_UPKb8 span{margin-left:.3rem;user-select:none}`, "",{"version":3,"sources":["webpack://./src/components/go-back/go-back.module.scss"],"names":[],"mappings":"AAAA,0BACC,cAAA,CACA,kBAAA,CACA,aAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,kCAAA,CAEA,gCACC,aAAA,CACA,oBAAA,CAGD,+BACC,iBAAA,CACA,gBAAA","sourcesContent":["span.go-back-container {\n\tcursor: pointer;\n\tfont-weight: normal;\n\tcolor: rgb(99, 115, 129);\n\tpadding: 0.6rem 0;\n\tdisplay: flex;\n\talign-items: center;\n\twidth: fit-content;\n\tfont-family: 'Noto Sans', sans-serif;\n\n\t&:hover {\n\t\tcolor: #212b36;\n\t\ttext-decoration: none;\n\t}\n\n\tspan {\n\t\tmargin-left: 0.3rem;\n\t\tuser-select: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"go-back-container": `lwtL9dMde9KYxo_UPKb8`
};
export default ___CSS_LOADER_EXPORT___;
