import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import axios from "axios";
import {Apis} from "../../util/Apis";
import {errorToast, successToast} from "../../components/Layaout/ToastStyles";
import PageSection from "../../components/PageSection";
import PageContainer from "../../components/PageContainer";
import PageTitle from "../../components/Layaout/PageTitle";
import {Preview} from "../../components/forms/Preview";
import SalasForm from "./SalasForm";
import {useNavigate} from "react-router-dom";
import {decimalToInteger} from "./EditSalas";


const NewSalas = () => {

  const navigate = useNavigate();
  const {getValues, setValue} = useForm();
  const [pcs, setPcs] = useState([])

  const goBack = () => {
    navigate(-1)
  }

  const create = async (params) => {
    params.salaId = parseInt(params.salaId)
    params.companyId = parseInt(params.companyId)
    params.registroMincetur = parseInt(params.registroMincetur)
    params.ruc = parseInt(params.ruc)
    params.pcs = pcs
    params.config = {
      onlineSystem: params.config.onlineSystem,
      onlinePassword: params.config.onlinePassword,
      onlineUrl: params.config.onlineUrl,
      onlineUrlPrivate: params.config.onlineUrlPrivate,
      onlineUsername: params.config.onlineUsername,
      periodScan: parseInt(params.config.periodScan),
      periodScanSessions: parseInt(params.config.periodScanSessions),
      scanMeter: params.config.scanMeter,
      scanCard: params.config.scanCard
    }

    params.billing = {
      ruc: parseInt(params.billing.ruc),
      machinesQuantity: parseInt(params.billing.machinesQuantity),
      fee: decimalToInteger(params.billing.fee),
      currency: params.billing.currency,
      invoiceDescription: params.billing.invoiceDescription,
      active: params.billing.active,
      plan: params.billing.plan,
      paymentCycle: params.billing.paymentCycle,
      email: params.billing.email
    }

    params.currency = {
      currencyIso: params.currency.currencyIso,
      currencySymbol: params.currency.currencySymbol
    }

    await axios.post(Apis.SALAS + '/salas', params)
      .then(res => {
        successToast()
        navigate('/salas-list')
      }).catch(err => {
        errorToast()
      })
  }

  const getPcs = () => {
    let pcsNew = {
      id: parseInt(getValues('idPCS')),
      name: getValues('namePCS'),
      mode: getValues('modePCS')
    }
    setPcs([...pcs, pcsNew])
    setValue('idPCS', "")
    setValue('namePCS', "")
    setValue('modePCS', "")
  }

  return (
    <PageContainer>
      <PageSection style={{padding: '20px'}}>
        <Preview text='Regresar' onClick={goBack}/>
        <PageTitle title={'Nueva sala'}/>

        <div>
          <SalasForm value={{}} onSubmit={create} mode={false}/>
        </div>
      </PageSection>

    </PageContainer>
  )
    ;
};

export default NewSalas;
