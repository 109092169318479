// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.xYSY3XfDPL4C5u83qH5T{margin:16px 8px;padding-bottom:16px;transition:.5s all ease-in-out}@media screen and (min-width: 768px){div.xYSY3XfDPL4C5u83qH5T{transition:.5s all ease-in-out;margin:22px 22px}}`, "",{"version":3,"sources":["webpack://./src/components/page-section/page-section.module.scss"],"names":[],"mappings":"AAAA,yBACE,eAAA,CACA,mBAAA,CACA,8BAAA,CAEA,qCALF,yBAMI,8BAAA,CACA,gBAAA,CAAA","sourcesContent":["div.page-section-container {\n  margin: 16px 8px;\n  padding-bottom: 16px;\n  transition: 0.5s all ease-in-out;\n\n  @media screen and (min-width: 768px) {\n    transition: 0.5s all ease-in-out;\n    margin: 22px 22px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page-section-container": `xYSY3XfDPL4C5u83qH5T`
};
export default ___CSS_LOADER_EXPORT___;
