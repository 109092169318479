import React from 'react';
import styled from 'styled-components';

const LoadingAnimate = styled.div`
  position: relative;
  width: 16px;
  height: 16px;

  &.primary {
    > div {
    width: 100%;
    height: 100%;
    background-color: #ffc107;
    border-radius: 50%;
    animation: spinner-4t3wzl 1.25s infinite backwards;
  }
  > div:nth-child(1) {
    animation-delay: 0.15s;
    background-color: #ffc107;
  }

  > div:nth-child(2) {
    animation-delay: 0.3s;
    background-color: #ffc107de;
  }

  > div:nth-child(3) {
    animation-delay: 0.45s;
    background-color: #ffc107b5;
  }

  > div:nth-child(4) {
    animation-delay: 0.6s;
    background-color: #ffc1078c;
  }

  > div:nth-child(5) {
    animation-delay: 0.75s;
    background-color: #ffc10773;
  }
  }

  &.secondary {
    > div {
    width: 100%;
    height: 100%;
    background-color: #068eef;
    border-radius: 50%;
    animation: spinner-4t3wzl 1.25s infinite backwards;
  }
  > div:nth-child(1) {
    animation-delay: 0.15s;
    background-color: #068eef;
  }

  > div:nth-child(2) {
    animation-delay: 0.3s;
      background-color: #068eefde;
  }
  > div:nth-child(3) {
    animation-delay: 0.45s;
    background-color: #068eefb5;
  }

  > div:nth-child(4) {
    animation-delay: 0.6s;
    background-color: #068eef8c;
  }

  > div:nth-child(5) {
    animation-delay: 0.75s;
    background-color: #068eef73;
  }
  }

  @keyframes spinner-4t3wzl {
    0% {
      transform: rotate(0deg) translateY(-200%);
    }

    60%,
    100% {
      transform: rotate(360deg) translateY(-200%);
    }
  }
`;

export const Loading = ({ variant, ...props }) => {
  let variantClass = ''
  switch (variant) {
    case 'primary':
      variantClass = 'primary'
      break;
    case 'secondary':
      variantClass = 'secondary'
      break;
    default:
      variantClass = 'primary'
      break;
  }

  return (
    <LoadingAnimate className={variantClass} {...props}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LoadingAnimate>
  );
};
