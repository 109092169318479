import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import {useParams} from "react-router";
import PageSection from "../../components/PageSection";
import {Col, Row} from "react-bootstrap";
import FormGroup from "../../components/FormGroup";
import Input from "../../components/forms/FormEl";

const LudopForm = ({value, onSubmit, setAction}) => {
  const {register, handleSubmit, watch, reset, control} = useForm();

  const {id} = useParams()

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])
  return (
    <div>
      <PageSection>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Nombre</label>
              <Input type={'text'} key={id} {...register('firstName')}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Apellidos</label>
              <Input  type={'text'} key={id} {...register('lastName1')}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>DNI</label>
              <Input  type={'text'} key={id} {...register('document')}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
        <Col>
          <FormGroup>
            <label style={{fontWeight: 'bold'}}>Dirección</label>
            <Input  type={'text'} key={id} {...register('location')}/>
          </FormGroup>
        </Col>
      </Row>
      </PageSection>
    </div>
  );
};
export default LudopForm;
