import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import {useParams} from "react-router";
import PageSection from "../../../components/PageSection";
import {Col, Row} from "react-bootstrap";
import FormGroup from "../../../components/FormGroup";
import Input from "../../../components/forms/FormEl";

const PangolinLoteForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, watch, reset, control} = useForm();

  const {id} = useParams()

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    if (value) {
      reset(value)
    }
  }, [value])

  return (
    <div>
      <PageSection>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Cantidad</label>
              <Input type={'text'} key={id} {...register('quantity')}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Version de placa</label>
              <Input type={'text'} key={id} {...register('boardVersion')}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label style={{fontWeight: 'bold'}}>Comentario</label>
              <textarea
                {...register('comment')}
                rows="4"
                cols="50"
                style={{width: '100%', padding: '0.5rem', borderColor: '#ccc', borderRadius: '4px'}}
              />
            </FormGroup>
          </Col>
        </Row>
      </PageSection>
    </div>
  );
};

export default PangolinLoteForm;
