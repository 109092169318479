import React, {useState, useEffect} from 'react';
import PageContainer from "../../components/PageContainer";
import PageSection from "../../components/PageSection";
import PageTitle from "../../components/Layaout/PageTitle";
import {Table} from "../../components/forms/Table";
import {Apis} from "../../util/Apis";
import useFetch from "../../hooks/useFetch";
import {Loading} from "../../components/forms/Loading";
import {ContainerLoading} from "../SalasList";
import SelectSalas from "../../Selects/SelectSalas";
import FormGroup from "../../components/FormGroup";
import {Controller, useForm} from "react-hook-form";
import Input from "../../components/forms/FormEl";
import {Slidebar} from "../../components/forms/Slidebar";
import {Button} from "@wargostec/react-components";

const ClientsList = () => {
  let defaultParams = {salaId: 0, size : 400, term: ''}
  const [slideOpen, setSlideOpen] = useState(false)
  const [params, setParams] = useState(defaultParams)
  const {loading, data, fetchData, error} = useFetch(Apis.USER + '/internal/users/search', [], params);
  const {register, control, handleSubmit, setValue} = useForm({defaultValues: defaultParams});
  const [salaNumber, setSalaNumber] = useState(0)

  const getData = (params) => {
    setSalaNumber(params.salaId)
    setParams(params)
  }

  let search = params
  const handleSearch = e => {
    search.term = e.target.value
    setParams(search)
    fetchData()
  }

  useEffect(() => {
    if (params) {
      if (params.salaId == 0) {
        params.salaId = ''
      }
      fetchData()
    }

  }, [params])


  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Nombre',
      accessor: 'firstName',
      Cell: ({cell}) => (
        <div>{cell.value + ' ' + cell.row.original.lastName}</div>
      )
    },
    {
      Header: 'Usuario',
      accessor: 'username',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Sala',
      accessor: 'salaname',
    }
  ];
  return (
    <PageContainer>
      <PageSection>
        <PageTitle
          title={params.salaId > 0 ? `Clientes de la sala ${salaNumber}` : 'Clientes de todas las salas'}/>
        <Button variant={'primary'} text={'Filtrar'} onClick={() => setSlideOpen(!slideOpen)}/>
      </PageSection>

      <PageSection>
        <Input type={'text'} {...register('term')} placeholder='Filtrar por nombre' onChange={handleSearch}/>
      </PageSection>

      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <div>
              {
                data.length > 0 &&
                <Table columns={columns} data={data && data} pagination paginationSize={20}/>
              }
            </div>
        }
      </PageSection>

      <Slidebar title={'Filtros'} visible={slideOpen} onHide={() => setSlideOpen(false)}>
        <div style={{padding: '16px'}}>
          <FormGroup>
            <label style={{fontWeight: 'bold'}}>Sala</label>
            <Controller
              name="salaId"
              control={control}
              render={({field}) => <SelectSalas {...field} />}
            />
          </FormGroup>
          <Button variant={'primary'} onClick={handleSubmit(getData)} text={'Guardar'}/>
        </div>
      </Slidebar>
    </PageContainer>
  );
}

export default ClientsList;
