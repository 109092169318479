import React from 'react'
import styles from './col.module.scss'

type ColProps = {
  children?: React.ReactNode
}

const Col: React.FC<ColProps> = ({children}) => {
  return (
    <div className={styles['col-container']}>
      {children}
    </div>
  )
}

export default Col