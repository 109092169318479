import React, {useEffect} from 'react';
import axios from "axios";
import {Apis} from "../../../util/Apis";
import InvoiceForm from "./InvoiceForm";

const InvoiceNew = ({setAction, setTitle}) => {

  const create = async (params) => {
    const input = params.date.split('-');
    params.year = input[0];
    params.month = input[1];
    return await axios.post(Apis.BILLING + '/invoices', params).catch((err) => {
    });
  }

  useEffect(() => {
    setTitle("Nueva factura");
  }, []);

  return (
    <>
      <InvoiceForm value={{}} onSubmit={create} setAction={setAction}/>
    </>
  );
};

export default InvoiceNew;
