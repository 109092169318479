import React, {useEffect} from 'react';
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import axios from "axios";
import VersionForm from "./VersionForm";

const VersionEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch(Apis.SALAS + '/salas/' + id);

  function update(params) {
    params.id = id
    return axios.put(Apis.SALAS + '/salas/' + id, params);
  }

  useEffect(() => {
    setTitle('Nueva version');
  }, []);

  return (
    <div>
      {!loading && data &&
        <VersionForm value={data} onSubmit={update} setAction={setAction}/>
      }
    </div>
  );
};

export default VersionEdit;
