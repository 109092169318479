import React from 'react';
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import {SelectV2} from "../components/forms/FormEl";

const SelectPangolinLote  = React.forwardRef((props, ref) => {
  const {data, loading} = useFetch(Apis.TGM + '/pangolin-lote', [])

  return (
    <>
      {
        !loading ?
          <SelectV2 ref={ref} options={data && data} {...props}/>
          :
          <SelectV2 disabled options={[{id: 0, name: 'Seleccione...'}]}/>
      }
    </>
  )
})

export default SelectPangolinLote;
