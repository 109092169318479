import React, {useEffect, useState} from 'react';
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import {SelectV2} from "../components/forms/FormEl";
import Select from "react-select";

const SelectTenant = React.forwardRef((props, ref) => {

  const {data, loading, error} = useFetch(Apis.USER + '/internal/tenants', [])
  const [selectedOption, setSelectedOption] = useState(null)
  const [optionsFormat, setOptionsFormat] = useState([])

  const prevOnChange = (selected) => {
    props.onChange(selected.value)
    props.setTenant && props.setTenant(selected.value)
  }

  useEffect(() => {
    const selected = optionsFormat.find(option => option.value === props.value)
    setSelectedOption(selected)
  }, [props.value])

  useEffect(() => {
    if (data) {
      const formatted = data.sort((a, b) => a.name.localeCompare(b.name)).map((item) => {
        return {value: item.name, label: item.name};
      });
      setOptionsFormat(formatted)
      if (props.value) {
        const selected = formatted.find(option => option.value === props.value)
        setSelectedOption(selected)
      }
    }
  }, [data])

  return (
    <>
      {
        !loading ?
          <Select
            value={selectedOption}
            onChange={prevOnChange}
            options={optionsFormat}
          />
          :
          <SelectV2 disabled options={[{id: 0, name: 'Cargando...'}]}/>
      }
    </>
  )
})

export default SelectTenant;
