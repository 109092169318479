import { useState } from "react"
import Card from "../card"
import Button from "../button"
import styles from './date-filter.module.scss'
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import FlexContainer from "../flex-container"
import moment from "moment"
import 'moment/locale/es';

type DateFilterProps = {
  dates?: { ini: string; fin: string }
  defaultMode?: 'daily' | 'weekly' | 'monthly' | 'yearly'
  weekly?: boolean
  monthly?: boolean
  yearly?: boolean
  onChange?: (dates: { ini: string; fin: string }) => void
  onClickCustom?: () => void
}

const DateFilter: React.FC<DateFilterProps> = ({ dates = { ini: new Date().toISOString().split('T')[0], fin: new Date().toISOString().split('T')[0] }, defaultMode = 'daily', onChange, onClickCustom, weekly = false, monthly = false, yearly = false }) => {
  const [mode, setMode] = useState<'daily' | 'weekly' | 'monthly' | 'yearly' | 'custom'>((defaultMode === 'monthly' && !monthly) || (defaultMode === 'weekly' && !weekly) || (defaultMode === 'yearly' && !yearly) ? 'daily' : defaultMode)

  const getTitle = () => {
    if (mode === 'daily') return moment(dates.ini).format('LL').toUpperCase()
    else if (mode === 'weekly') return moment(dates.ini).format('LL').toUpperCase() + ' - ' + moment(dates.fin).format('LL').toUpperCase()
    else if (mode === 'monthly') return moment(dates.ini).format('MMMM [de] YYYY').toUpperCase()
    else if (mode === 'yearly') return moment(dates.ini).format('YYYY').toUpperCase()
    else return moment(dates.ini).format('LL').toUpperCase() + ' - ' + moment(dates.fin).format('LL').toUpperCase()
  }

  const move = (step: 1 | -1 = 1) => {
    if (mode === 'daily') onChange && onChange({ ini: moment(dates.ini).add(step, 'day').format('YYYY-MM-DD'), fin: moment(dates.fin).add(step, 'day').format('YYYY-MM-DD') })
    else if (mode === 'weekly') onChange && onChange({ ini: moment(dates.ini).add(step, 'week').startOf('isoWeek').format('YYYY-MM-DD'), fin: moment(dates.fin).add(step, 'week').endOf('isoWeek').format('YYYY-MM-DD') })
    else if (mode === 'monthly') onChange && onChange({ ini: moment(dates.ini).add(step, 'month').startOf('month').format('YYYY-MM-DD'), fin: moment(dates.fin).add(step, 'month').endOf('month').format('YYYY-MM-DD') })
    else if (mode === 'yearly') onChange && onChange({ ini: moment(dates.ini).add(step, 'year').startOf('year').format('YYYY-MM-DD'), fin: moment(dates.fin).add(step, 'year').endOf('year').format('YYYY-MM-DD') })
  }

  const setActualDate = () => {
    if (mode === 'daily') onChange && onChange({ ini: moment().format('YYYY-MM-DD'), fin: moment().format('YYYY-MM-DD') })
    else if (mode === 'weekly') onChange && onChange({ ini: moment().startOf('isoWeek').format('YYYY-MM-DD'), fin: moment().endOf('isoWeek').format('YYYY-MM-DD') })
    else if (mode === 'monthly') onChange && onChange({ ini: moment().startOf('month').format('YYYY-MM-DD'), fin: moment().endOf('month').format('YYYY-MM-DD') })
    else if (mode === 'yearly') onChange && onChange({ ini: moment().startOf('year').format('YYYY-MM-DD'), fin: moment().endOf('year').format('YYYY-MM-DD') })
  }

  const onChangeMode = (mode: 'daily' | 'weekly' | 'monthly' | 'yearly' | 'custom' = 'daily') => {
    setMode(mode)
    if (mode === 'daily') onChange && onChange({ ini: moment().format('YYYY-MM-DD'), fin: moment().format('YYYY-MM-DD') })
    else if (mode === 'weekly') onChange && onChange({ ini: moment().startOf('isoWeek').format('YYYY-MM-DD'), fin: moment().endOf('isoWeek').format('YYYY-MM-DD') })
    else if (mode === 'monthly') onChange && onChange({ ini: moment().startOf('month').format('YYYY-MM-DD'), fin: moment().endOf('month').format('YYYY-MM-DD') })
    else if (mode === 'yearly') onChange && onChange({ ini: moment().startOf('year').format('YYYY-MM-DD'), fin: moment().endOf('year').format('YYYY-MM-DD') })
    else onClickCustom && onClickCustom()
  }

  return (
    <Card backgroundColor="#0A0A0A">
      <FlexContainer justifyContent="space-between" gap={16} alignItems="center" className={styles['date-filter-container']}>
        <FlexContainer alignItems="center" gap={8}>
          {mode !== 'custom' && <FaChevronLeft fontSize={'20'} cursor='pointer' color="white" onClick={() => move(-1)} />}
          <span style={{ color: 'white', textAlign: 'center', userSelect: 'none' }}>{getTitle()}</span>
          {mode !== 'custom' && <FaChevronRight fontSize={'20'} cursor='pointer' color="white" onClick={() => move()} />}
        </FlexContainer>
        <div className={styles['options']}>
          <Button
            text="Actual"
            size="sm"
            variant="success"
            onClick={setActualDate}
            disabled={mode === 'custom'}
          />
          <div className={styles['divider']}></div>
          <Button
            text="Día"
            size="sm"
            variant={mode === 'daily' ? 'primary' : 'light'}
            onClick={() => onChangeMode()}
          />
          {weekly &&
            <Button
              text="Semana"
              size="sm"
              variant={mode === 'weekly' ? 'primary' : 'light'}
              onClick={() => onChangeMode('weekly')}
            />
          }
          {monthly &&
            <Button
              text="Mes"
              size="sm"
              variant={mode === 'monthly' ? 'primary' : 'light'}
              onClick={() => onChangeMode('monthly')}
            />
          }
          {yearly &&
            <Button
              text="Año"
              size="sm"
              variant={mode === 'yearly' ? 'primary' : 'light'}
              onClick={() => onChangeMode('yearly')}
            />
          }
          {onClickCustom && <Button
            text="Personalizado"
            size="sm"
            variant={mode === 'custom' ? 'primary' : 'light'}
            onClick={() => onChangeMode('custom')}
          />}
        </div>
      </FlexContainer>
    </Card>
  )
}

export default DateFilter