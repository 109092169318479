import React from 'react';
import PageSection from "../components/PageSection";

const OnlineSystem = () => {
  return (
    <>
      <PageSection>
        <h5 style={{fontSize: '1.5rem'}}>
          Sistema Online
        </h5>

      </PageSection>
    </>
  );
};

export default OnlineSystem;
