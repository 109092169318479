import React, {useEffect} from 'react';
import MakerForm from "./MakerForm";
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import axios from "axios";

const MakerEdit = ({id, setAction, setTitle}) => {

  const {loading, data} = useFetch(Apis.SALAS + '/manufacturer/' + id);

  function update(params) {
    params.id = id
    params.manufacturerId = parseInt(params.manufacturerId)
    return axios.put(Apis.SALAS + '/manufacturer/' + id, params);
  }

  useEffect(() => {
    setTitle('Editar proveedor');
  }, []);

  return (
    <div>
      {!loading && data &&
        <MakerForm value={data} onSubmit={update} setAction={setAction}/>
      }
    </div>
  );
};

export default MakerEdit;
