import React, {useCallback, useMemo, useState} from 'react';
import PageSection from "../components/PageSection";
import {Apis} from "../util/Apis";
import styled from "styled-components";
import useFetch from "../hooks/useFetch";
import axios from "axios";
import PageTitle from "../components/Layaout/PageTitle";
import {successToast} from "../components/Layaout/ToastStyles";
import ReactCountryFlag from "react-country-flag";
import {MdFiberManualRecord} from "react-icons/md";
import {Modalverification} from "../components/forms/ModalVerification";
import {Loading} from "../components/forms/Loading";
import {Button, Table} from "@wargostec/react-components";
import PageContainerFull from "../components/PageContainerFull";
import {useNavigate} from "react-router-dom";

export const ContainerLoading = styled.div`
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SalasList = () => {

  const {loading, data, fetchData} = useFetch(Apis.SALAS + '/salas', []);
  const navigate = useNavigate();

  function goEditSalas(id) {
    navigate("/salas-edit/" + id);
  }

  function goNewSalas() {
    navigate("/salas-new");
  }

  function goVersion(id) {
    navigate("/detail-version/" + id);
  }

  const deleteElement = (id) => {
    axios.delete(Apis.SALAS + '/salas/' + id)
      .then(res => {
        successToast('Se elimino correctamente')
        fetchData()
      })
      .catch(error => {
      })
  }

  const actionValue = useCallback(props => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isDelete, setIsDelete] = useState(false);
    return (
      <>
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <Button text={'Editar'} size={'xs'} variant={'success'} onClick={() => goEditSalas(props.renderValue())}/>
          <Button text={'Eliminar'} size={'xs'} variant={'danger'} onClick={() => setIsDelete(true)}/>
          <Modalverification
            show={isDelete}
            handleClose={() => setIsDelete(false)}
            title='¿Seguro que desea eliminar este Sala?'
            handleDelete={() => deleteElement(props.renderValue())}
          />
        </div>
      </>
    );
  }, []);

  const countryValue = useCallback(props => <>
      {
        <div>
          <ReactCountryFlag
            countryCode={props.renderValue()}
            svg
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            style={{
              fontSize: '1.6em',
              lineHeight: '1.6em',
            }}
          />
        </div>
      }
    </>
    , []);

  const billingValue = useCallback(props => <>
      {
        <div>
          {props.renderValue() ?
            <MdFiberManualRecord color={'#60FC1C'} size={28}/> :
            <MdFiberManualRecord color={'#bdb6b5'} size={28}/>}
        </div>
      }
    </>
    , []);

  const columns = useMemo(
    () => [
      {header: 'SalaID', accessorKey: 'salaId'},
      {
        header: 'Pais',
        accessorKey: 'countryIso',
        cell: countryValue,
      },
      {header: 'Tenant', accessorKey: 'tenant'},
      {header: 'Nombre', accessorKey: 'name'},
      {header: 'Empresa', accessorKey: 'companyName'},
      {header: 'Sistema', accessorKey: 'config.onlineSystem'},
      {header: 'Usuario', accessorKey: 'config.onlineUsername'},
      {
        header: 'Facturación',
        accessorKey: 'billing.active',
        cell: billingValue,
      },
      {
        header: 'Acciones',
        accessorKey: 'id',
        cell: actionValue,
      },
    ]
    , []
  )

  return (
    <PageContainerFull>
      <PageSection>
        <PageTitle title={'Salas'}/>
        <Button text={'Nuevo'} variant={'primary'} onClick={goNewSalas}/>
      </PageSection>

      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <div>
              {
                data && data.salas &&
                <div>
                  <h4>Total: {data.totalBilling}</h4>
                  <h4>Suctr: {data.totalSuctr}</h4>
                  <br/>
                  <Table
                    columns={columns}
                    data={data.salas}
                    pageSize={100}
                    sortArray={[{id: 'salaId', desc: true}]}
                    searchable={true}
                  />
                </div>

              }
            </div>

        }
      </PageSection>
    </PageContainerFull>
  );
};

export default SalasList;
