// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li.sD_Bfhi5x5virC5WpHL8{cursor:pointer;background:rgba(0,0,0,0);transition:all 100ms ease-in-out 0s;overflow:hidden;white-space:nowrap;display:flex;list-style:none}li.sD_Bfhi5x5virC5WpHL8>a{padding:8px 12px;color:#fff;flex:1;text-decoration:none;font-weight:400;font-family:"Noto Sans",sans-serif;font-size:14px;user-select:none}li.sD_Bfhi5x5virC5WpHL8>a:hover,li.sD_Bfhi5x5virC5WpHL8>a.zQdc4shsCQ0SzOzM6X5Q{background:#404040;border-radius:6px}`, "",{"version":3,"sources":["webpack://./src/components/menu-item/menu-item.module.scss"],"names":[],"mappings":"AAAA,wBACC,cAAA,CACA,wBAAA,CACA,mCAAA,CACA,eAAA,CACA,kBAAA,CACA,YAAA,CAEA,eAAA,CAEA,0BACC,gBAAA,CACA,UAAA,CACA,MAAA,CACA,oBAAA,CACA,eAAA,CACA,kCAAA,CACA,cAAA,CACA,gBAAA,CAEA,+EAEC,kBAAA,CACA,iBAAA","sourcesContent":["li.menu-item-container {\n\tcursor: pointer;\n\tbackground: transparent;\n\ttransition: all 100ms ease-in-out 0s;\n\toverflow: hidden;\n\twhite-space: nowrap;\n\tdisplay: flex;\n\n\tlist-style: none;\n\n\t& > a {\n\t\tpadding: 8px 12px;\n\t\tcolor: #fff;\n\t\tflex: 1;\n\t\ttext-decoration: none;\n\t\tfont-weight: 400;\n\t\tfont-family: 'Noto Sans', sans-serif;\n\t\tfont-size: 14px;\n\t\tuser-select: none;\n\n\t\t&:hover,\n\t\t&.active {\n\t\t\tbackground: #404040;\n\t\t\tborder-radius: 6px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu-item-container": `sD_Bfhi5x5virC5WpHL8`,
	"active": `zQdc4shsCQ0SzOzM6X5Q`
};
export default ___CSS_LOADER_EXPORT___;
