import React, {useCallback, useEffect, useMemo, useState} from 'react';
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import axios from "axios";
import useEditModal from "../../hooks/useEditModal";
import FirmwareEdit from "./FirmwareEdit";
import FirmwareNew from "./FirmwareNew";
import PageSection from "../../components/PageSection";
import PageTitle from "../../components/Layaout/PageTitle";
import {ContainerLoading} from "../SalasList";
import {Loading} from "../../components/forms/Loading";
import GenericModal2 from "../../hooks/GenericModal2";
import PageContainer from "../../components/PageContainer";
import {Button, Table} from "@wargostec/react-components";
import {Modalverification} from "../../components/forms/ModalVerification";
import {DateTime} from "luxon";
import * as FaIcons from "react-icons/fa";
import {errorToast, successToast} from "../../components/Layaout/ToastStyles";
import {SwitchToggle} from "../../components/forms/SwitchToggle";

const FirmwareList = () => {

  const {loading, data, fetchData} = useFetch(Apis.SALAS + '/firmware', []);

  const deleteElement = (id) => {
    axios.delete(Apis.SALAS + '/firmware/' + id)
      .then(res => {
        fetchData()
      })
      .catch(error => {
      })

  }

  const {wrapper: wrapper1, refresh: refresh1, addAction, editAction} = useEditModal({
    editComponent: <FirmwareEdit/>,
    addComponent: <FirmwareNew/>
  })


  useEffect(() => {
    fetchData()
  }, [refresh1])

  const copyContent = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  const enableFirmware = async (id, enable) => {
    if (!enable) {
      await axios.post(Apis.SALAS + '/firmware/' + id + '/enable')
        .then(() => {
            fetchData()
            successToast("Firmware habilitado")
          }
        ).catch(error => {
          errorToast("No se puede habilitar el firmware")
        })
    } else {
      await axios.post(Apis.SALAS + '/firmware/' + id + '/disable')
        .then(() => {
            fetchData()
            successToast("Firmware deshabilitado")
          }
        ).catch(error => {
          errorToast("No se puede deshabilitar el firmware")
        })
    }
  }

  const actionValue = useCallback(props => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isDelete, setIsDelete] = useState(false);
    return (
      <>
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
          {/*<Button text={'Editar'} size={'xs'} variant={'success'} onClick={() => editAction(props.renderValue())}/>*/}
          <Button text={'Eliminar'} size={'xs'} variant={'danger'} onClick={() => setIsDelete(true)}/>
          <Modalverification
            show={isDelete}
            handleClose={() => setIsDelete(false)}
            title='¿Seguro que desea eliminar este firmware?'
            handleDelete={() => deleteElement(props.renderValue())}
          />
        </div>
      </>
    );
  }, []);

  const createdValue = useCallback(props => <>
      {
        <div>
          {props.renderValue() ?
            <span>{DateTime.fromISO(props.renderValue()).toFormat("yyyy'-'LL'-'dd' 'HH':'mm' ")}</span> :
            <span>---</span>}
        </div>
      }
    </>
    , []);

  const versionValue = useCallback(props => <>
      {
        <div>
          <Button icon={<FaIcons.FaCopy size="15px"/>} style={{paddingLeft: '5px'}} size={'xs'} variant={'light'}
                  onClick={() => copyContent(props.renderValue())}>
          </Button>
          {" " + props.renderValue()}
        </div>
      }
    </>
    , []);

  const columns = useMemo(
    () => [
      {
        header: 'Versión',
        accessorKey: 'version',
        cell: versionValue
      },
      {
        header: 'sha1', accessorKey: 'sha1'
      },
      {
        header: 'Habilitado',
        accessorKey: 'enabled',
        cell: valueEnable => {
          return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <SwitchToggle
                style={{justifyContent: 'center'}} defaultChecked={valueEnable.renderValue()}
                name={"enabled"}
                label={"Hab"}
                onClick={() => enableFirmware(valueEnable.row.original.id, valueEnable.renderValue())}/>
            </div>
          )
        }
      },
      {
        header: 'Fecha de creación',
        accessorKey: 'createdAt',
        cell: createdValue
      },
      {
        header: 'Acciones',
        accessorKey: 'id',
        cell: actionValue
      },
    ]
    , []
  )

  return (
    <PageContainer>
      <PageSection>
        <PageTitle title={'Firmware'}/>
        <Button text={'Nuevo'} variant={'primary'} onClick={addAction}/>
      </PageSection>

      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <Table columns={columns} data={data} pageSize={100} sortArray={[{id: 'createdAt', desc: true}]} searchable={true}/>
        }
      </PageSection>
      <GenericModal2 {...wrapper1}/>
    </PageContainer>
  );
};

export default FirmwareList;
