import React, {useEffect} from 'react';
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import axios from "axios";
import DevicesForm from "./DevicesForm";

const DevicesEdit = ({id, setAction, setTitle})  => {

  const {loading, data} = useFetch(Apis.TGM + `/pangolin/` + id);

  function update(params) {
    return axios.put(Apis.TGM + '/pangolin/' + id, params);
  }

  useEffect(() => {
    setTitle('SN : ' + id);
  }, []);
  return (
    <div>
      {!loading && data &&
        <DevicesForm value={data} onSubmit={update} setAction={setAction}/>
      }
    </div>
  );
};

export default DevicesEdit;
