// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vpn7fqxdAZb_5K0h4B8M{width:100%;display:none}.vpn7fqxdAZb_5K0h4B8M.YkjvjeJUpOFdZ7mIP0MQ{display:block}`, "",{"version":3,"sources":["webpack://./src/components/tab-content/tab-content.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CAEA,2CACE,aAAA","sourcesContent":[".tab-content-container {\n  width: 100%;\n  display: none;\n\n  &.active {\n    display: block;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab-content-container": `vpn7fqxdAZb_5K0h4B8M`,
	"active": `YkjvjeJUpOFdZ7mIP0MQ`
};
export default ___CSS_LOADER_EXPORT___;
