import {forwardRef} from 'react'
import styles from './select.module.scss'

type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  label?: string
  helper?: string
  error?: string
  children: React.ReactNode
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(({id = '', label, helper, error, children, ...props}, ref) => {
  return (
    <div className={styles['select-container']}>
      {label && <label htmlFor={id}>{label}</label>}
      <select id={id} ref={ref} {...props} className={error && error.trim().length > 0 ? styles['error'] : ''}>
        {children}
      </select>
      {helper && (!error || props.disabled) && <label>{helper}</label>}
      {error && !props.disabled && <label className={`${styles['error']}`}>{error}</label>}
    </div>
  )
})

Select.displayName = "Select"

export default Select