import {NavLink} from 'react-router-dom'
import styles from './menu-item.module.scss'

type MenuItem = {
  title?: string
  path?: string
  onClick?: () => void
}

const MenuItem: React.FC<MenuItem> = ({title, path, onClick = () => {}}) => {
  return (
    <li className={`${styles['menu-item-container']}`} >
      <NavLink to={path} className={({isActive}) =>
        [
          isActive ? styles['active'] : ""
        ].join(" ")
      } onClick={onClick}>
        {title}
      </NavLink>
    </li>

  )
}

export default MenuItem