import React, {useRef, useState} from 'react'
import Select from "react-select";

const MachineSelectV2 = React.forwardRef((props, ref) => {

  const [selectedMachine, setSelectedMachine] = useState(null);
  const machineSelectRef = useRef(null);

  console.log(props.data)
  const handleMachineChange = (selectedOption) => {
    setSelectedMachine(selectedOption);
    props.onChange(selectedOption.value);
  };

  const machineOptions = props.data && props.data.length > 0 && props.data?.map(machine => ({
    value: machine.confId,
    label: machine.machine,
  })) || [];

  return (
    <Select
      options={machineOptions}
      value={selectedMachine}
      onChange={handleMachineChange}
      placeholder="Seleccione..."
      autoFocus
      ref={machineSelectRef}
      styles={{
        container: (baseStyles) => ({
          ...baseStyles,
          width: '100%'
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          display: 'none'
        })
      }}
    />  )
})

export default MachineSelectV2
