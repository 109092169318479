import React, {useState} from 'react';
import PageSection from "../components/PageSection";
import {ContainerLoading} from "./SalasList";
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import {Loading} from "../components/forms/Loading";
import {Table} from "../components/forms/Table";

const Midas = () => {

  const {loading, data} = useFetch(Apis.SALAS + '/sala-scanner/' + 999, []);

  const columns = [
    { Header: 'Id', accessor: 'id' },
    { Header: 'SalaId', accessor: 'salaId' },
    { Header: 'Url', accessor: 'url' },
    { Header: 'Zone', accessor: 'zone' },
    { Header: 'Connected', accessor: 'connected' },
    { Header: 'ConnectedAt', accessor: 'connectedAt' },
  ];

  const items = [
    {
      id : 1,
      salaId: 1021,
      url : "www.wargostec.com",
      zone: 2,
      connected : "Conectado",
      connectedAt: "---",

    },
    {
      id : 1,
      salaId: 1021,
      url : "www.wargostec.com",
      zone: 2,
      connected : "Conectado",
      connectedAt: "---",
    },
    {
      id : 1,
      salaId: 1021,
      url : "www.wargostec.com",
      zone: 2,
      connected : "Conectado",
      connectedAt: "---",
    },
    {
      id : 1,
      salaId: 1021,
      url : "www.wargostec.com",
      zone: 2,
      connected : "Conectado",
      connectedAt: "---",
    }
  ]
  return (
    <>
      <PageSection>
        <h5 style={{fontSize: '1.5rem'}}>
          Midas
        </h5>
      </PageSection>
      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <Table columns={columns} data={items} title={"Tabla"} />
        }
      </PageSection>

    </>
  );
};

export default Midas;
